(function () {
    /**
     * Jump to an element on mousewheel down
     *
     * @constructor
     */
    Tollwerk.Scrollnav = function () {

        /**
         * All elements the user can jump to
         * @typ {NodeList}
         */
        this.elements = document.querySelectorAll('.scroll-nav-item');
        if (!this.elements) return null;

        /**
         * The next element to jump to when swiping or scrolling (with mousewheel) down
         * @type {object}
         */
        this.nextElement = null; //

        // Listen do scroll events to determine the next element
        this.getNextElement();
        this.scrollTimeout = null;
        window.addEventListener('scroll', function (event) {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(function () {
                this.getNextElement();
            }.bind(this), 10);
        }.bind(this), {passive: true});


        // Listen scrollwheel, trigger jump to next element
        window.addEventListener('wheel', this.checkJumpToNext.bind(this), { passive: false });

        // Add listeners to iframes if there are any
        let iFrames = document.querySelectorAll('iframe');
        iFrames.forEach(iFrame => {
            iFrame.addEventListener('wheel', this.checkJumpToNext.bind(this), {passive: false});
        });
    }

    /**
     * Jump to the next element if appropriate
     *
     * @param event
     * @returns {boolean}
     */
    Tollwerk.Scrollnav.prototype.checkJumpToNext = function (event) {

        // console.log('checkJumpToNext()');

        // Because this function will be called multiple times
        // when the mouse wheel is used, we have to disable
        // it when it already triggered jumping to an element.
        // We have to put it in the first place to prevent
        // undesired scrolling behaviour.

        if (this.scrollingLocked) {
            event.preventDefault();
        }

        // Check if we actually want to jump somewhere.
        // If not, disable the lock so the next mousewheel event
        // can trigger normal scrolling and exit.
        if (!this.nextElement || !this.nextElement.id) {
            // console.log('not scrolling 1');
            this.scrollingLocked = false;
            return false;
        }
        if (event.deltaY <= 0) {
            // console.log('not scrolling 2');
            this.scrollingLocked = false;
            return false;
        }

        // console.log('Scroll to next!');


        // Here, we know there is a valid next element to jump to.
        // So, prevent scrolling via mousewheel and lock it that way.
        // Then, jump to the next element and try to locate the following
        // next element.
        this.scrollingLocked = true;
        event.preventDefault();
        this.nextElement.scrollIntoView();
        this.nextElement.scrollIntoView();
        this.getNextElement();
        return true;
    }

    /**
     * Check if there is another element so jump to
     */
    Tollwerk.Scrollnav.prototype.getNextElement = function () {
        var windowHeight = window.innerHeight;
        var switchLocationHashAt = windowHeight * .5;
        var nextElement = null;
        var locationHash = null;
        this.nextElement = null;

        for (var i = 0, len = this.elements.length; i < len; i++) {
            var elementRect = this.elements[i].getBoundingClientRect();
            var computedStyle = getComputedStyle(this.elements[i]);
            var scrollMarginTop = Number(computedStyle.scrollMarginTop.replace('px', ''));
             if ((elementRect.bottom - scrollMarginTop) <= windowHeight && (elementRect.bottom - scrollMarginTop) > 1 && this.nextElement === null) {
                 var nextElement = this.elements[i + 1];
                 if(nextElement != 'undefined' && this.elements[i] !== nextElement) {
                     this.nextElement = nextElement;
                 } else {
                     this.nextElement = null;
                 }
            }
            if ((elementRect.top >= 0 && elementRect.top < (windowHeight * 0.5)) || (elementRect.bottom > (50 - scrollMarginTop) && (elementRect.bottom - scrollMarginTop) <= windowHeight)) {
                locationHash = this.elements[i].id;
            }
        }
        this.nextElement = nextElement;
        this.updateNavigationLinks(locationHash);
    }

    /**
     * Update the scoll navigation links. Set the one with
     * the matching locationHash as active.
     *
     * @param locationHash
     */
    Tollwerk.Scrollnav.prototype.updateNavigationLinks = function (locationHash) {
        var scrollnavigation = document.querySelector('.ScrollNavigation');
        if(scrollnavigation) {
            if (document.querySelector('.ScrollNavigationWrap').getBoundingClientRect().bottom < 0) {
                scrollnavigation.classList.add('hide-scroll-nav');
            } else {
                scrollnavigation.classList.remove('hide-scroll-nav');
            }
        }
        if (!locationHash) {
            return;
        }

        var navigationLinks = document.querySelectorAll('.ScrollNavigation__list .NavItem');
        for (var i = 0, len = navigationLinks.length; i < len; i++) {
            if (navigationLinks[i].getAttribute('aria-labelledby') == locationHash) {
                navigationLinks[i].classList.add('active');
            } else {
                navigationLinks[i].classList.remove('active');
            }
        }

        // If browser supports history.pushState,
        // update the location hash in the browser address bar
        // without scrolling there
        // if (history.pushState) {
        //     history.pushState(null, null, '#' + locationHash);
        // }
    }
})();

Tollwerk.Init.registerOnReady(function () {
    new Tollwerk.Scrollnav();
});


