(function () {

    /**
     * A youtube video element
     *
     * @param element
     * @constructor
     */
    Tollwerk.YoutubeVideo = function (element) {

        // Init basic properties
        this.element = element;
        this.youtubeId = this.element.hasAttribute('data-youtube') ? this.element.getAttribute('data-youtube') : null;
        this.thumbnail = element.querySelector('img');
        this.playerObject = null;
        if(!this.youtubeId) return;
        this.playerElement = document.getElementById('youtube-' + this.youtubeId + '-player');

        // Add event listeners
        this.element.addEventListener('click', function (event) {
            // Prevent default click behaviour
            event.preventDefault();

            // Do nothing if youtube players was already initialized
            if(this.playerObject) return;

            // Create youtube player for this element
            this.element.setAttribute('data-youtube-status', 'loading');
            this.playerObject = new YT.Player(this.playerElement.id, {
                videoId: this.youtubeId,
                height: 480,
                width: 640,
                events: {
                    'onReady': function (event) {
                        this.thumbnail.setAttribute('hidden', true);
                        this.thumbnail.setAttribute('aria-hidden', 'true');
                        event.target.playVideo();
                        this.playerElement.removeAttribute('hidden');
                        this.playerElement.removeAttribute('aria-hidden');
                        this.element.setAttribute('data-youtube-status', 'loaded');
                    }.bind(this)
                }
            });
        }.bind(this));
    }

})();

Tollwerk.Init.registerOnReady(function () {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
});

function onYouTubeIframeAPIReady() {
    // Get all youtube video items
    var youtubeItems = document.querySelectorAll('[data-youtube]');
    for (var i = 0, len = youtubeItems.length; i < len; i++) {
        new Tollwerk.YoutubeVideo(youtubeItems[i]);
    }
}