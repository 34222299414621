Tollwerk.Init.registerOnReady(function () {

    /**
     * @param element
     * @returns {boolean|TwShare}
     * @constructor
     */
    TwShare = function (trigger) {
        // Get all necessary elements. Return null if something is missing.
        this.trigger = trigger || null;
        if (!trigger) {
            return null;
        }

        this.triggerParent = this.trigger.closest('.ShareTrigger');
        if(!this.triggerParent) {
            return null;
        }

        this.id = trigger.attributes['data-share'] ? trigger.attributes['data-share'].value : null;
        if (!this.id) {
            return null;
        }

        this.content = document.getElementById(this.id);
        if (!this.content) {
            return null;
        }

        this.body = document.body;
        if (!this.body) {
            return null;
        }

        // Set initial state
        this.collapse();


        // If this.trigger is no <button> element, insert one.
        if (this.trigger.tagName.toLowerCase() != 'button') {
            var button = document.createElement('button');
            button.setAttribute('aria-controls', this.id);
            button.innerHTML = this.trigger.innerHTML;
            this.trigger.innerHTML = '';
            this.trigger.appendChild(button);
            this.trigger = button;

            var icon = document.createElement('span');
            icon.setAttribute('class', 'Icon Icon--inline Icon--theme-default');
            icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="100.00009" height="82.753616" viewBox="-0.05 0 29.050027 24.048201" version="1.1" id="svg4">\n' +
                '<g id="layer4" transform="translate(1.3721323e-7,-5.0117992)">\n' +
                '<path id="path2028-1-0" d="m 15.025391,5.0117188 c -7.6602158,0 -13.9804691,5.3485952 -13.9804691,12.0253902 0,1.718347 0.4649797,3.40809 1.2792969,4.955079 l -2.18945318,3.927734 c -0.40227341,0.720302 -0.14231924,1.647008 0.578125,2.050781 0.10997896,0.06368 0.51415858,0.285661 1.24218748,0.529297 0.7303794,0.244422 1.7384512,0.484375 2.9042969,0.484375 1.4685099,0 2.8475064,-0.409361 4.1015625,-1.146484 1.8942505,0.786105 3.9654695,1.222656 6.0644535,1.222656 7.659055,-0.003 13.980468,-5.351285 13.980468,-12.023438 0,-6.676852 -6.321204,-12.0253902 -13.980468,-12.0253902 z m 0,1.0976562 c 7.150132,0 12.880859,4.948151 12.880859,10.927734 0,5.974456 -5.730513,10.921028 -12.880859,10.923829 -2.038264,0 -4.064035,-0.418463 -5.8789066,-1.208985 l -0.2675782,-0.117187 -0.2460937,0.15625 c -1.1499548,0.730899 -2.4042609,1.095703 -3.7734375,1.095703 -1.0144926,0 -1.9089682,-0.211644 -2.5546875,-0.427735 -0.6457193,-0.21609 -1.0855262,-0.462995 -1.0449219,-0.439453 l -0.00391,-0.002 L 1.25,27.013672 C 1.047414,26.901177 0.97963715,26.658743 1.09375,26.455078 L 3.5605469,22.03125 3.4082031,21.761719 c -0.8303378,-1.466458 -1.265625,-3.08903 -1.265625,-4.72461 0,-5.97964 5.7316615,-10.927734 12.8828129,-10.927734 z m 0.08077,15.904376 c -0.497539,-0.539552 -4.48621,-3.939986 -4.937757,-5.233448 -0.4860427,-1.377113 0.240407,-2.88284 1.615954,-3.368019 1.341055,-0.47054 2.797088,0.208083 3.321803,1.514092 v 0 c 0.519489,-1.306009 1.979702,-1.984632 3.320757,-1.514092 1.375548,0.485179 2.098861,1.990906 1.615956,3.368019 -0.453638,1.294507 -4.441265,4.694942 -4.936713,5.233448 z"/>\n' +
                '</g>\n' +
                '</svg>';
            this.trigger.appendChild(icon);
        }

        // Add event listeners
        this.trigger.addEventListener('click', this.toggle.bind(this));

        // Return instance
        return this;
    };

    /**
     * Toggle between collapsing and expanding the content
     * @returns {boolean} Returns true if expanded, false if collapsed.
     */
    TwShare.prototype.toggle = function () {
        if (this.expanded) {
            this.collapse();
            return this.expanded;
        }

        this.expand();
        return this.expanded;
    };

    /**
     * Expand
     */
    TwShare.prototype.expand = function () {
        this.trigger.setAttribute('aria-expanded', 'true');
        this.triggerParent.setAttribute('aria-expanded', 'true');
        this.content.setAttribute('aria-hidden', 'false');
        this.body.setAttribute('data-share-open', 'true');

        // Remove tabindex -1 from all interactive elements
        var interactiveElements = this.content.querySelectorAll('input, textarea, select, a');
        for (var i = 0, len = interactiveElements.length; i < len; i++) {
            interactiveElements[i].removeAttribute('tabindex');
        }

        // Select the
        var input = this.content.querySelector('input');
        if (input) {
            input.select();
        }

        this.expanded = true;
    };

    /**
     * Collapse
     */
    TwShare.prototype.collapse = function () {
        this.trigger.setAttribute('aria-expanded', 'false');
        this.triggerParent.setAttribute('aria-expanded', 'false');
        this.content.setAttribute('aria-hidden', 'true');
        this.body.setAttribute('data-share-open', 'false');

        // Set tabindex of all interactive elements to -1
        var interactiveElements = this.content.querySelectorAll('input, textarea, select, a');
        for (var i = 0, len = interactiveElements.length; i < len; i++) {
            interactiveElements[i].setAttribute('tabindex', -1);
        }

        this.expanded = false;
    }

    var shareElements = document.querySelectorAll('[data-share]');
    for (var i = 0, len = shareElements.length; i < len; i++) {
        new TwShare(shareElements[i]);
    }
});