(function () {
    /**
     * Account registration form
     *
     * @returns {*}
     * @constructor
     */
    Tollwerk.RegisterAccountForm = function () {

        // Get DOM elements
        this.form = document.getElementById('frontendUserRegistration');
        this.formFields = this.form ? this.form.querySelectorAll('input, textarea, select') : [];
        if (!this.form || !this.formFields.length) {
            return null;
        }

        // Add event listeners for form field changes
        this.formFields.forEach(formField => {
            formField.addEventListener('change', this._handle_formFields_change.bind(this));
        });

        this.updateAccountTypeFields();
        return this;
    };

    /**
     * Handle form field changes
     *
     * @param event
     * @private
     */
    Tollwerk.RegisterAccountForm.prototype._handle_formFields_change = function (event) {
        switch (event.currentTarget.name) {
            case 'tx_twuser_feuserregistration[frontendUserRegistration][type]':
                this.updateAccountTypeFields();
                break;
        }
    };

    /**
     * Hide or show fields depending on selected account type (private person or institution)
     */
    Tollwerk.RegisterAccountForm.prototype.updateAccountTypeFields = function () {

        // Get elements to show or hide
        let institutionalFormElements = this.form.querySelectorAll('' +
            '#frontendUserRegistration-institute-fields, ' +
            '#frontendUserRegistration-fieldsetOwned, ' +
            '#frontendUserRegistration-instituteStaticText, ' +
            '#frontendUserRegistration-instituteInterestsFieldset'
        );
        let privateFormElements = this.form.querySelectorAll('' +
            '#frontendUserRegistration-interestsFieldset, ' +
            '#frontendUserRegistration-privateStaticText'
        )

        // Get currently selected account type
        // and decide what to do
        let type = this.form.querySelector('input[name="tx_twuser_feuserregistration[frontendUserRegistration][type]"]:checked');
        type = type ? type.value : null;

        switch (true) {
            // If no type was selected yet, hide all depending fields
            case type === null:
                this.showFields(privateFormElements, false);
                this.showFields(institutionalFormElements, false);
                break;
            // If private person
            case type == 1:
                this.showFields(privateFormElements, true);
                this.showFields(institutionalFormElements, false);
                break;
            // if institutional account
            case type == 2:
                this.showFields(privateFormElements, false);
                this.showFields(institutionalFormElements, true);
                break;
        }
    };

    /**
     * Show or hide form fields and their parent containers
     *
     * @param containers        DOM elements containing the form fields. The containers themselves will be hidden as well.
     * @param {boolean} show    Show if true, hide if false
     */
    Tollwerk.RegisterAccountForm.prototype.showFields = function (containers, show) {
        containers.forEach(container => {
            if (show) {
                container.removeAttribute('hidden');
                container.removeAttribute('aria-hidden');
            } else {
                container.setAttribute('hidden', true);
                container.setAttribute('aria-hidden', true);
            }

            let fields = container.querySelectorAll('input, select, textarea');
            fields.forEach(field => {
                if (show) {
                    field.removeAttribute('disabled');
                    if (field.type === 'text' || field.type === 'radio') {
                        field.setAttribute('required', 'required');
                    }
                } else {
                    field.setAttribute('disabled', 'disabled');
                    if (field.type === 'text' || field.type === 'radio') {
                        field.removeAttribute('required');
                    }
                }
            });
        });
    }
})();

/**
 * Init Tollwerk.RegisterAccountForm
 */
Tollwerk.Init.registerOnReady(function () {
    new Tollwerk.RegisterAccountForm();
});

