(function () {
    /**
     * Enhance the TYPO3 pagination widget with ajax
     * Your HTML will need the following attributes for this to work:
     *  • id: Necessary for finding the corresponding elements in the ajax response.
     *  • data-paginate: Can be Tollwerk.Paginate.MODE.readMore or Tollwerk.Paginate.MODE.paginate
     *  • data-paginate-type: The TYPO3 page type for the ajax call
     *
     * @param containerElement The parent DOM element containing a list and a pagination element
     *
     * @param {Object} [options] Available options
     * @param {string} [options.list='ol'] The CSS selector for the list element inside the given parent element.
     * @param {string} [options.pagination='.f3-widget-paginator'] The CSS selector for the TYPO3 pagination widget element inside the given parent element.
     * @param {string} [options.nextLink='a[rel="next"]'] The CSS selector for the next-link (needed for loadMore mode) inside the pagination widget element.
     * @param {string} [options.moreButtonClass=''] CSS class for the "Read more" button
     *
     * @returns {null}
     * @constructor
     */
    Tollwerk.Paginate = function (containerElement, options) {
        // Get the parent container
        this.containerElement = containerElement;
        if (!this.containerElement) return null;

        // Merge options with default options
        this.options = {};
        var options = options || {};
        for (prop in Tollwerk.Paginate.defaultOptions) {
            if (options.hasOwnProperty(prop)) {
                this.options[prop] = options[prop];
            } else {
                this.options[prop] = Tollwerk.Paginate.defaultOptions[prop];
            }
        }

        // Get other necessary DOM elements
        this.listElement = this.containerElement.querySelector(this.options.list);
        this.paginationElement = this.containerElement.querySelector(this.options.pagination);
        if (!this.listElement || !this.paginationElement) return null;

        // Set other properties
        this.status = null;
        this.pageType = Number(this.containerElement.getAttribute('data-paginate-type'));
        if (!this.pageType) {
            return null;
        }

        // Initialize and return instance
        this.init();
        return this;
    }

    /**
     * Default options
     *
     * @type {{mode: string, listElement: string, paginationElement: string}}
     */
    Tollwerk.Paginate.defaultOptions = {
        list: 'ol',
        pagination: '.f3-widget-paginator',
        nextLink: 'a[rel="next"]',
        moreButtonClass: '',
    };

    /**
     * Allowed modes for the pagination behaviour,
     * e.g. just enhancing the existing pagination with ajax features
     * or replacing it with a "Load more" button
     *
     * @type {{pagination: string, loadMoreButton: string}}
     */
    Tollwerk.Paginate.MODE = {
        loadMore: 'loadMore',
        pagination: 'pagination'
    };

    /**
     * Status like loading, ready, error ..
     *
     * @type {{ready: string, loading: string, locked: string, error: string}}
     */
    Tollwerk.Paginate.STATUS = {
        init: 'init',
        ready: 'ready',
        loading: 'loading',
        locked: 'locked',
        error: 'error',
    };

    /**
     * Initialize the enhanced behaviour
     * according to the selected mode ("pagination" or "loadMore")
     */
    Tollwerk.Paginate.prototype.init = function () {
        this.setStatus(Tollwerk.Paginate.STATUS.init);

        // Get mode from data attribute
        var mode = this.containerElement.getAttribute('data-paginate');
        if (!mode) {
            console.error('Could not retrive mode from data-paginate attribute');
            return;
        }
        ;

        // Init behaviour based on mode
        switch (mode) {
            case Tollwerk.Paginate.MODE.pagination:
                // TODO: Implement
                return;
            case Tollwerk.Paginate.MODE.loadMore:
                // Remove pagination
                this.paginationElement.parentNode.removeChild(this.paginationElement);

                // Get the 'next' link from pagination.
                // Without this there is no need to proceed.
                var nextLink = this.paginationElement.querySelector(this.options.nextLink);
                if (!nextLink) {
                    return;
                }

                // Create load more buttons
                this.moreButton = document.createElement('button');
                this.moreButton.textContent = 'More'; // TODO: Get from options or data attribute
                this.moreButton.className = 'paginate-load-more ' + this.options.moreButtonClass;
                this.moreButton.href = nextLink.href + '&type=' + this.pageType;
                this.moreButton.addEventListener('click', function (event) {
                    this.load(event.currentTarget.href, function (response) {
                        // Get the next nextLink from the ajax response
                        var newNextLink = response.querySelector(this.options.pagination + ' ' + this.options.nextLink);
                        if (!newNextLink) {
                            this.moreButton.parentNode.removeChild(this.moreButton);
                            return;
                        }
                        this.moreButton.href = newNextLink.href + '&type=' + this.pageType;
                    }.bind(this));
                }.bind(this));
                this.containerElement.appendChild(this.moreButton);

                // Finish
                this.setStatus(Tollwerk.Paginate.STATUS.ready);
                return;
            default:
                console.error('Mode \'' + mode + '\' not supported!');
                return;
        }
    }

    /**
     * Load new items via ajax
     *
     * @param {string} uri The uri for the ajax call
     * @param {function} [callback] The callback function for the successful ajax call
     * @param {object} [callback.response] The response object
     */
    Tollwerk.Paginate.prototype.load = function (uri, callback) {
        this.setStatus(Tollwerk.Paginate.STATUS.loading);
        // Prepare request
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'document';
        xhr.open('GET', uri);

        // Listen events
        xhr.addEventListener('load', function (callback, event) {
            var response = event.currentTarget.response;

            // Add new list items
            var newListItems = response.querySelectorAll(this.options.list + '> li');
            for (var i = 0, len = newListItems.length; i < len; i++) {
                this.listElement.appendChild(newListItems[i]);
            }

            // Execute callback
            if (callback) {
                try {
                    callback(response);
                } catch (error) {
                    console.error(error);
                    this.setStatus(Tollwerk.Paginate.STATUS.error);
                }
            }

            // Done
            this.setStatus(Tollwerk.Paginate.STATUS.ready);
        }.bind(this, callback));
        xhr.addEventListener('error', function (event) {
            this.setStatus(Tollwerk.Paginate.STATUS.error);
        }.bind(this));

        // Send request
        xhr.send();
    }

    /**
     * Set the status
     *
     * @param status
     * @returns {null}
     */
    Tollwerk.Paginate.prototype.setStatus = function (status) {
        // Check if status parameter is valid
        if (!status || !Tollwerk.Paginate.STATUS.hasOwnProperty(status)) return null;

        // Determin which status to set
        switch (status) {
            default:
                this.status = status;
        }

        // Update all CSS classes
        this.containerElement.setAttribute('data-status', status);
    }


})();

Tollwerk.Init.registerOnReady(function () {
    var paginatedLists = document.querySelectorAll('*[data-paginate]');
    for (var i = 0, len = paginatedLists.length; i < len; i++) {
        new Tollwerk.Paginate(paginatedLists[i], {
            moreButtonClass: 'CallToAction CallToAction--link  CallToAction-theme--turquoise'
        });
    }
});
