function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

$(document).ready(function() {
    if (window.innerWidth < 990 ) {
        var bLazy = new Blazy({
            selector: '.b-lazy',
            loadInvisible: true,
            success: function(element) {
                setTimeout(function() {
                    var parent = element.parentNode;
                    parent.className = parent.className.replace(/\bloading\b/, '');
                }, 20);
            }
        });
    } else {
        document.querySelectorAll('img.b-lazy').forEach(function(image){
            image.classList.add('b-loaded');
            image.src = image.dataset.src;
        });
    }
    if(document.querySelectorAll('.search--slider').length >= 1) {
        tns({
            container: '.search--slider',
            center: true,
            edgePadding: 0,
            nav: false,
            autowidth: true,
            items: 1,
            slideBy: 1,
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: 3000,
            controls: true,
            touch: true,
            mouseDrag: true,
            rewind: false,
            swipeAngle: 25,
            autoHeight: false,
            preventScrollOnTouch: 'auto',
            loop: true,
            speed: 900
        });
    }

    // Slider for new releases on home page
    $('.new-release-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 900
    });
    // Slider for offers on home page
    $('.offer-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    // Video modal in offer slider
    $('.video-modal').magnificPopup({
        delegate: 'a',
        type: 'iframe'
    });

    //Counter function for facsimile search
    /*!
     * Bez @VERSION
     * http://github.com/rdallasgray/bez
     *
     * A plugin to convert CSS3 cubic-bezier co-ordinates to jQuery-compatible easing functions
     *
     * With thanks to Nikolay Nemshilov for clarification on the cubic-bezier maths
     * See http://st-on-it.blogspot.com/2011/05/calculating-cubic-bezier-function.html
     *
     * Copyright @YEAR Robert Dallas Gray. All rights reserved.
     * Provided under the FreeBSD license: https://github.com/rdallasgray/bez/blob/master/LICENSE.txt
     */
    (function(factory) {
        if (typeof exports === "object") {
            factory(require("jquery"));
        } else if (typeof define === "function" && define.amd) {
            define(["jquery"], factory);
        } else {
            factory(jQuery);
        }
    }(function($) {
        $.extend({ bez: function(encodedFuncName, coOrdArray) {
            if ($.isArray(encodedFuncName)) {
                coOrdArray = encodedFuncName;
                encodedFuncName = 'bez_' + coOrdArray.join('_').replace(/\./g, 'p');
            }
            if (typeof $.easing[encodedFuncName] !== "function") {
                var polyBez = function(p1, p2) {
                    var A = [null, null], B = [null, null], C = [null, null],
                        bezCoOrd = function(t, ax) {
                            C[ax] = 3 * p1[ax], B[ax] = 3 * (p2[ax] - p1[ax]) - C[ax], A[ax] = 1 - C[ax] - B[ax];
                            return t * (C[ax] + t * (B[ax] + t * A[ax]));
                        },
                        xDeriv = function(t) {
                            return C[0] + t * (2 * B[0] + 3 * A[0] * t);
                        },
                        xForT = function(t) {
                            var x = t, i = 0, z;
                            while (++i < 14) {
                                z = bezCoOrd(x, 0) - t;
                                if (Math.abs(z) < 1e-3) break;
                                x -= z / xDeriv(x);
                            }
                            return x;
                        };
                    return function(t) {
                        return bezCoOrd(xForT(t), 1);
                    }
                };
                $.easing[encodedFuncName] = function(x, t, b, c, d) {
                    return c * polyBez([coOrdArray[0], coOrdArray[1]], [coOrdArray[2], coOrdArray[3]])(t/d) + b;
                }
            }
            return encodedFuncName;
        }});
    }));

    $('.counter').each(function() {
        let separator = $(this).data('kseparator');
        if (typeof separator === 'undefined') {
            separator = ',';
        }
        $(this).prop('Counter', 0).animate({
            Counter: $(this).attr('data-count').replace(/,/g, '')
        }, {
            duration: 15000,
            easing: $.bez([0.2,1,0.2,1]),
            step: function(now) {
                var formatedNow = Math.ceil(now).toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join(separator);
                $(this).text(formatedNow);
            }
        });
    });

    //Timer for subnavigation to prevent the closing
    let timer;
    $(".Landmark__nav-item .Landmark__nav-item-expand, .NavSecondary").on("mouseover", function() {
        clearTimeout(timer);
        openSubmenu();
    }).on("mouseleave", function() {
        timer = setTimeout(
            closeSubmenu
            , 500);
    });
    $(".UserNav, .UserNav__links").on("mouseover", function() {
        clearTimeout(timer);
        openAccountmenu();
    }).on("mouseleave", function() {
        timer = setTimeout(
            closeAccountmenu
            , 500);
    });
    function openAccountmenu() {
        $(".UserNav__links").addClass("open");
    }
    function closeAccountmenu() {
        $(".UserNav__links").removeClass("open");
    }
    function openSubmenu() {
        $(".NavSecondary").addClass("open");
    }
    function closeSubmenu() {
        $(".NavSecondary").removeClass("open");
    }

    $(".expander").each(function(){
        var container = $(this);
        if((container.find('.row-to-expand').length > 0) && (container.find('.row-to-expand .div-to-expand').length > 0)){
            container.find('.row-to-expand').eq(0).nextAll().hide().addClass('toggleable');
            container.find('.row-to-expand .div-to-expand').eq(7).nextAll().hide().addClass('toggleable-divs');
        }
        if((container.find('.row-to-expand-chessboard').length > 0) && (container.find('.row-to-expand-chessboard .div-to-expand').length > 0)){
            container.find('.row-to-expand-chessboard').eq(0).nextAll().hide().addClass('toggleable');
            container.find('.row-to-expand-chessboard .div-to-expand').eq(3).nextAll().hide().addClass('toggleable-divs');
        }
        container.append('<div class="read-more"></div>');
        container.find('.read-more').on('click', function(e) {
            let button = $(e.target);
            let opened = button.hasClass('read-less');
            let parent = button.parents('.expander').first();

            if (opened) {
                button.removeClass('read-less');
                parent.find('.row-to-expand').removeClass('toggleable');
                parent.find('.row-to-expand').siblings('.row-to-expand.toggleable').slideUp();
                parent.find('.row-to-expand .div-to-expand').siblings('.row-to-expand .div-to-expand.toggleable-divs').slideUp();

                parent.find('.row-to-expand-chessboard').siblings('.row-to-expand-chessboard.toggleable').slideUp();
                parent.find('.row-to-expand-chessboard .div-to-expand').siblings('.row-to-expand-chessboard .div-to-expand.toggleable-divs').slideUp();
                parent.find('.row-to-expand-chessboard .div-to-expand:nth-child(4)').removeClass('uncut');
            } else {
                button.addClass('read-less');
                parent.find('.row-to-expand').addClass('toggleable');
                parent.find('.row-to-expand').siblings('.row-to-expand.toggleable').slideDown();
                parent.find('.row-to-expand .div-to-expand').siblings('.row-to-expand .div-to-expand.toggleable-divs').slideDown();

                parent.find('.row-to-expand-chessboard').siblings('.row-to-expand-chessboard.toggleable').slideDown();
                parent.find('.row-to-expand-chessboard .div-to-expand').siblings('.row-to-expand-chessboard .div-to-expand.toggleable-divs').slideDown();
                parent.find('.row-to-expand-chessboard .div-to-expand:nth-child(4)').addClass('uncut');
            }
        });
    });

    // Set objects of same kind or same class to the highest height, e. g. tiles on home page
    function equalizeHeight(myClass) {
        var maxHeight = 0;
        $(myClass).each(function () {
            if ($(this).height() > maxHeight) {
                maxHeight = $(this).height();
            }
        });
        $(myClass).height(maxHeight);
    }

    function initEqualHeight(myClass) {
        setTimeout(equalizeHeight(myClass), 1000);
        window.addEventListener('resize', function(event){
            $(myClass).height("auto");
            setTimeout(equalizeHeight(myClass), 1000);
        });
    }

    initEqualHeight('.category-tile');

    //hide/show Navigation on scroll
    var lastScrollTop = 120;
    $(window).on('scroll', function() {
        let scroll = $(this).scrollTop();
        if(scroll == 120) {
            $('.Landmark--main-navigation').removeClass('navigation-sticky, navigation-show');
            $('.NavSearch__glass').attr('aria-expanded', 'false');
        }
        else {
            $('.Landmark--main-navigation').addClass('navigation-sticky');
        }
        if (scroll <= lastScrollTop || lastScrollTop < 120){
            $('.Landmark--main-navigation').addClass('navigation-show');
            $('.Landmark--banner').removeClass('navigation-hidden');
        } else {
            $('.Landmark--main-navigation').removeClass('navigation-show');
            $('.NavSearch__glass').attr('aria-expanded', 'false');
            $('.Landmark--banner').addClass('navigation-hidden');
        }
        lastScrollTop = scroll;
    });

    //Add class to open mobile nav
    $('.NavPrimary__toggle').on('click', function() {
        $('.Landmark--main-navigation .Landmark__inner').toggleClass('open');
    });

    initializeSearchResultToDetailLinks();

    if (typeof searchPageUrl !== 'undefined') {
        var referrer = document.referrer;
        if (referrer.indexOf(searchPageUrl) === 0) {
            let searchHash = getCookie('tw_ziereis_last_search');
            if (searchHash && searchHash !== '') {
                previousSearchUrl = previousSearchUrl.replace('SEARCH_HASH_PLACEHOLDER', searchHash);
                document.querySelectorAll('a.DocumentHero__back__link').forEach(function(backLink) {
                    backLink.classList.add('enabled');
                    backLink.closest(".DocumentHero__back").classList.add('enabled');
                    backLink.addEventListener('click', function(event) {
                        event.preventDefault();
                        window.location = previousSearchUrl;
                    });
                });
            }
        }
    }

    //Detail page mobile tab navigation
    // function mobileTabNav() {
    //     $('.Tabs__nav-item').children('a').removeClass('Tabs__prev-tab');
    //     $('.Tabs__nav-item').children('a').removeClass('Tabs__next-tab');
    //     $('.Tabs__nav-item').children('a[aria-selected="true"]').parent().prev().children('a').addClass('Tabs__prev-tab');
    //     $('.Tabs__nav-item').children('a[aria-selected="true"]').parent().next().children('a').addClass('Tabs__next-tab');
    // }

    // setTimeout (function() {
    //     mobileTabNav();
    // }, 2000 );
    $('.Tabs__nav-item').on('click', function(){
        var scroll = $(".Tabs").offset().top;
        $(window).scrollTop( scroll );
        setTimeout (function() {
            $('.Landmark--main-navigation').removeClass('navigation-show');
            $('.Landmark--banner').addClass('navigation-hidden');
        }, 1000 );
        // mobileTabNav();
    });

    $('.Facsimile--editions-short .Tabs__nav-item .facsimile--edition-tab').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let dataHrefFacsimileEdition = event.target.parentElement.getAttribute('data-href');
        let offset = 150;
        let el = document.querySelector('#' + dataHrefFacsimileEdition);

        if($('.Tabs .Tabs__nav-item #-Faksimile-Editionen').length > 0) {
            $('.Tabs .Tabs__nav-item #-Faksimile-Editionen').attr('aria-selected', true);
        } else if ($('.Tabs .Tabs__nav-item #-Facsimile-editions').length > 0) {
            $('.Tabs .Tabs__nav-item #-Facsimile-editions').attr('aria-selected', true);
        }

        setTimeout(function(){
            window.scroll({ top: (el.offsetTop - offset), left: 0, behavior: 'smooth' });
        }, 50);

        setTimeout (function() {
            $('.Landmark--main-navigation').removeClass('navigation-show');
            $('.Landmark--banner').addClass('navigation-hidden');
        }, 1000 );

        $('.Tabs__nav-item').children('a').removeClass('Tabs__prev-tab');
        $('.Tabs__nav-item').children('a').removeClass('Tabs__next-tab');
        $('.Tabs__nav-item').children('a[aria-selected="true"]').parent().prev().children('a').addClass('Tabs__prev-tab');
    });

    var players = $('.player');

    players.each(function(_, player) {
        let videoOverlay = $(player).next();

        $(videoOverlay).on('click', function(e) {
            e.preventDefault();
            var videoBOX = $(this).parent();
            let youtubeId = e.target.getAttribute('data-youtube');

            player.setAttribute("src", "https://www.youtube-nocookie.com/embed/" + youtubeId + "?autoplay=1&rel=0&start=10");

            videoBOX.find('.video-overlay').hide();
        });
    });
});

function initializeSearchResultToDetailLinks() {
    document.querySelectorAll('#document-list a[data-search-hash]').forEach(function(link) {
        link.addEventListener('click', function(event){
            var searchHash = '';
            if (event.path) {
                for (var i in event.path) {
                    targetElement = event.path[i];
                    if (targetElement.dataset.searchHash) {
                        searchHash = targetElement.dataset.searchHash;
                        break;
                    }
                }
            } else {
                var targetElement = event.target;
                while(targetElement) {
                    if (targetElement.dataset.searchHash) {
                        searchHash = targetElement.dataset.searchHash;
                        break;
                    }
                    targetElement = targetElement.parentNode;
                }
            }

            if (searchHash !== '') {
                var expire = new Date();
                expire.setTime(expire.getTime() + (1*60*60*1000));
                document.cookie = 'tw_ziereis_last_search' + '=' + searchHash + ';path=/;max-age=31536000;expires=' + expire.toUTCString() + '';
            }
        });
    }, true);
}

// Contact form submit button deactivated until click into form to prevent bots
$(document).ready(function() {
    $('.Contact .Form__body .Form__navigation button').prop("disabled", true);

    $('.Contact .Form__body .required').on('click', function () {
        $('.Contact .Form__body .Form__navigation button').prop("disabled", false);
    });
});
