window.onload = function () {
    initialiseSlider();
};


function initialiseSlider(element) {
    /*
     * container where the script searches for a .SliderContainer
     * if empty defaults to entire document
     */
    element = (element ? document.querySelector(element) : document);

    if (!element) {
        return false;
    }

    /* all sliders on a page */
    var sliders = element.querySelectorAll('.SliderContainer');

    sliders.forEach(function (item) {

        var thisSlider = item.querySelector('.Slider');
        var numberItems = thisSlider.dataset.tnsItems; //number of items shown at a time
        var navItems = item.querySelectorAll('.NavItem');
        var textNavItems = item.querySelectorAll('.TextNavItem__button');
        var description = item.querySelector('.Slider__description');
        var gutter = ((numberItems > 1) ? 48 : 0);
        var partOf = item.querySelector('.Part__Of');
        var speed = 1000; //speed of transition in ms
        var speedcoverflow = 500; //speed of transition in ms


        if (!thisSlider.querySelector('.Slider__item')) {
            item.classList.add('empty');
            return false;
        }

        if (thisSlider.querySelectorAll('.Slider__item').length == 1) {
            item.classList.add('one-item');
            return false;
        }

        if (item.classList.contains('CoverSlider__slider') && thisSlider.querySelectorAll('.Slider__item').length == 2) {
            item.classList.add('two-items');
            return false;
        }


        /* initiate a specific slider */
        var slider;


        if (item.classList.contains('TileNav__slider')) {
            slider = tns({
                container: thisSlider,
                nav: false,
                items: 1,
                slideBy: 1,
                controls: false,
                touch: true,
                mouseDrag: true,
                swipeAngle: 25,
                autoHeight: true,
                gutter: gutter,
                rewind: true,
                preventScrollOnTouch: 'auto',
                speed: speed,
                responsive: {
                    609: {
                        items: 2
                    },
                    798: {
                        items: 3
                    },
                    988: {
                        items: 4
                    },
                    1425: {

                        items: numberItems
                    }
                }
            });

        } else if (item.classList.contains('CoverSlider__slider')) {
            slider = tns({
                container: thisSlider,
                center: true,
                edgePadding: 0,
                nav: false,
                autowidth: true,
                items: 1,
                slideBy: 1,
                startIndex: 1,
                controls: false,
                touch: true,
                mouseDrag: true,
                rewind: true,
                swipeAngle: 25,
                autoHeight: false,
                gutter: gutter,
                preventScrollOnTouch: 'auto',
                speed: speedcoverflow,
                responsive: {
                    833: {
                        items: numberItems,
                    }
                }
            });
            slider.goTo(1);
        } else if (item.classList.contains('PhotowallSlider__slider')) {
            slider = tns({
                container: thisSlider,
                nav: false,
                items: 1,
                slideBy: 1,
                controls: false,
                touch: true,
                mouseDrag: true,
                swipeAngle: 25,
                autoHeight: true,
                gutter: gutter,
                preventScrollOnTouch: 'auto',
                rewind: true,
                speed: speed,
                autoplay: true,
                autoplayTimeout: 9000,
                responsive: {
                    833: {
                        items: numberItems,
                    }
                }
            });
        } else if (item.classList.contains('ImageSlider--primaryFacsimile')) {
            slider = tns({
                container: thisSlider,
                nav: false,
                items: 1,
                slideBy: 1,
                controls: false,
                touch: true,
                mouseDrag: true,
                swipeAngle: 25,
                preventScrollOnTouch: 'auto',
                autoHeight: false,
                gutter: gutter,
                rewind: true,
                loop: true,
                speed: speed,
                responsive: {
                    833: {
                        items: numberItems,
                    }
                },
                onInit: function(info) {
                    if (info.items === 3) {
                        for (let i = 0, len = info.slideItems.length; i < len; i++) {
                            info.slideItems[i].classList.remove('center-item');
                        }
                        info.slideItems[info.displayIndex].classList.add('center-item');
                    }
                }
            });
        } else if (item.classList.contains('BlogSliderFullscreen')) {
            slider = tns({
                container: thisSlider,
                nav: false,
                items: 1,
                autoplay: true,
                autoplayTimeout: 9000,
                slideBy: 1,
                controls: false,
                touch: true,
                mouseDrag: true,
                swipeAngle: 25,
                preventScrollOnTouch: 'auto',
                autoHeight: false,
                gutter: gutter,
                rewind: true,
                speed: speed,
                autoplayHoverPause: false,
                responsive: {
                    833: {
                        items: numberItems,
                    }
                }
            });
        } else {
            slider = tns({
                container: thisSlider,
                nav: false,
                items: 1,
                slideBy: 1,
                controls: false,
                touch: true,
                mouseDrag: true,
                swipeAngle: 25,
                preventScrollOnTouch: 'auto',
                autoHeight: true,
                gutter: gutter,
                rewind: true,
                loop: true,
                speed: speed,
                responsive: {
                    833: {
                        items: numberItems,
                    }
                }
            });
        }

        var slides = slider.getInfo().slideItems;


        document.addEventListener('scroll', function () {
            var bounding = thisSlider.getBoundingClientRect();

            if (
                bounding.top >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                pauseStart(slider, 0);
            } else {
                slider.pause();
            }
        }, {passive: true});

        // initialisation of slider
        if (slider) {
            initialiseSliderState(slider, item, thisSlider, partOf, slides, description);
        }


        /* draging function */
        slider.events.on('dragEnd', function () {
            removeActive(navItems);
            removeActive(textNavItems);

            var index = slider.getInfo().index - slider.getInfo().cloneCount;
            var indexCached = slider.getInfo().indexCached - slider.getInfo().cloneCount;

            if (item.classList.contains('CoverSlider__slider')) {
                enlargeCenter(slider, thisSlider);
                if (partOf) {
                    setPartOf((slider.getInfo().displayIndex - 1), slider.getInfo().slideCount, partOf);
                }
            }

            if (index == slider.getInfo().slideCount || index > slider.getInfo().slideCount) {
                if (navItems.length != '0') {
                    setActive(navItems[0]);
                }
                if (textNavItems.length != '0') {
                    setActive(textNavItems[0]);
                }

                activateLinks(slides[1]);
            } else if (index < 0) {
                if (navItems.length != '0') {
                    setActive(navItems[slider.getInfo().slideCount + index]);
                }
                if (textNavItems.length != '0') {
                    setActive(textNavItems[slider.getInfo().slideCount + index]);
                }
                activateLinks(slides[slider.getInfo().slideCount]);
            } else {
                if (indexCached > index) {
                    if (navItems.length != '0') {
                        setActive(navItems[index]);
                    }
                    if (textNavItems.length != '0') {
                        setActive(textNavItems[index]);
                    }
                    activateLinks(slides[index]);
                } else {
                    if (navItems.length != '0') {
                        setActive(navItems[index]);
                    }
                    if (textNavItems.length != '0') {
                        setActive(textNavItems[index]);
                    }
                    activateLinks(slides[indexCached + 1]);
                }
            }

            if (partOf) {
                setPartOf(index, slider.getInfo().slideCount, partOf);
            }

            pauseStart(slider, 11000);
            /* Handle description */
            updateDescription(description, slider);
        });

        /* touch function */
        slider.events.on('touchEnd', function () {
            removeActive(navItems);
            removeActive(textNavItems);

            var index = slider.getInfo().index - slider.getInfo().cloneCount;
            var indexCached = slider.getInfo().indexCached - slider.getInfo().cloneCount;

            if (item.classList.contains('CoverSlider__slider')) {
                enlargeCenter(slider, thisSlider);
                if (partOf) {
                    setPartOf((slider.getInfo().displayIndex - 1), slider.getInfo().slideCount, partOf);
                }
            }

            if (index == slider.getInfo().slideCount || index > slider.getInfo().slideCount) {
                if (navItems.length != '0') {
                    setActive(navItems[0]);
                }
                if (textNavItems.length != '0') {
                    setActive(textNavItems[0]);
                }

                activateLinks(slides[1]);
            } else if (index < 0) {
                if (navItems.length != '0') {
                    setActive(navItems[slider.getInfo().slideCount + index]);
                }
                if (textNavItems.length != '0') {
                    setActive(textNavItems[slider.getInfo().slideCount + index]);
                }
                activateLinks(slides[slider.getInfo().slideCount]);
            } else {
                if (indexCached > index) {
                    if (navItems.length != '0') {
                        setActive(navItems[index]);
                    }
                    if (textNavItems.length != '0') {
                        setActive(textNavItems[index]);
                    }
                    activateLinks(slides[index]);
                } else {
                    if (navItems.length != '0') {
                        setActive(navItems[index]);
                    }
                    if (textNavItems.length != '0') {
                        setActive(textNavItems[index]);
                    }
                    activateLinks(slides[indexCached + 1]);
                }
            }

            if (partOf) {
                setPartOf(index, slider.getInfo().slideCount, partOf);
            }

            pauseStart(slider, 11000);
            /* Handle description */
            updateDescription(description, slider);
        });

        /* next button */
        var nextlist = item.querySelectorAll('.next')
        nextlist.forEach(
            function (next) {
                if (next) {
                    next.addEventListener('click', function () {

                        slider.goTo('next');

                        removeActive(navItems);
                        removeActive(textNavItems);
                        deactivateLinks(slides);

                        if (partOf) {
                            setPartOf(slider.getInfo().index, slider.getInfo().slideCount, partOf);
                        }

                        if (slider.getInfo().indexCached == slider.getInfo().slideCount - 1) {
                            if (navItems.length != '0') {
                                setActive(navItems[0]);
                            }
                            if (textNavItems.length != '0') {
                                setActive(textNavItems[0]);
                            }
                            activateLinks(slides[0]);
                        } else {
                            if (navItems.length != '0') {
                                setActive(navItems[slider.getInfo().index]);
                            }
                            if (textNavItems.length != '0') {
                                setActive(textNavItems[slider.getInfo().index]);
                            }
                            activateLinks(slides[slider.getInfo().indexCached]);
                        }

                        if (item.classList.contains('CoverSlider__slider')) {
                            if (partOf) {
                                setPartOf((slider.getInfo().displayIndex - 1), slider.getInfo().slideCount, partOf);
                            }
                            enlargeCenter(slider, thisSlider);
                        }

                        /* Handle description */
                        updateDescription(description, slider);
                        pauseStart(slider, 11000);
                    });
                }
            }
        )


        /* previous button */
        var prevlist = item.querySelectorAll('.prev');
        prevlist.forEach(
            function (prev) {
                if (prev) {
                    prev.addEventListener('click', function () {
                        if (slider.getInfo().index == 0) {
                            /* if on first slide */
                            slider.goTo(slider.getInfo().slideCount);
                            slowdownSlider(slider);
                        } else {
                            slider.goTo('prev');
                        }
                        removeActive(navItems);
                        removeActive(textNavItems);
                        deactivateLinks(slides);
                        if (partOf) {
                            setPartOf(slider.getInfo().index, slider.getInfo().slideCount, partOf);
                        }

                        if (slider.getInfo().indexCached == 0) {
                            if (navItems.length != '0') {
                                setActive(navItems[slider.getInfo().slideCount - 1]);
                            }
                            if (textNavItems.length != '0') {
                                setActive(textNavItems[slider.getInfo().slideCount - 1]);
                            }
                            activateLinks(slides[slider.getInfo().slideCount - 1]);
                        } else {
                            if (navItems.length != '0') {
                                setActive(navItems[slider.getInfo().indexCached - 1]);
                            }
                            if (textNavItems.length != '0') {
                                setActive(textNavItems[slider.getInfo().indexCached - 1]);
                            }
                            activateLinks(slides[slider.getInfo().index]);
                        }

                        if (item.classList.contains('CoverSlider__slider')) {
                            if (partOf) {
                                setPartOf((slider.getInfo().displayIndex - 1), slider.getInfo().slideCount, partOf);
                            }
                            enlargeCenter(slider, thisSlider);
                        }

                        /** Handle description **/
                        updateDescription(description, slider);

                        pauseStart(slider, 11000);
                    });
                }
            }
        )

        slider.events.on('indexChanged', function (info, eventName) {
            if (info.items === 3) {
                for (let i = 0, len = info.slideItems.length; i < len; i++) {
                    info.slideItems[i].classList.remove('center-item');
                }
                info.slideItems[info.displayIndex].classList.add('center-item');
            }
            removeActive(navItems);
            setActive(navItems[slider.getInfo().index]);
        });

        /* Circle navigation */
        if (navItems) {
            navItems.forEach(function (navItem) {
                navItem.addEventListener('click', function () {
                    slider.goTo(navItem.dataset.index);
                    removeActive(navItems);
                    setActive(this);

                    if (textNavItems) {
                        removeActive(textNavItems);
                        setActive(textNavItems[navItem.dataset.index]);
                    }
                    pauseStart(slider, 11000);
                })
            })
        }

        /* Textnvigation */
        if (textNavItems) {
            textNavItems.forEach(function (textNavItem) {
                textNavItem.addEventListener('click', function () {
                    slider.goTo(textNavItem.dataset.index);
                    removeActive(textNavItems);
                    setActive(this);

                    if (navItems) {
                        removeActive(navItems);
                        setActive(navItems[textNavItem.dataset.index]);
                    }
                    pauseStart(slider, 11000);
                })
            })
        }

        /* Animation classes */
        slider.events.on('transitionStart', function () {
            slider.getInfo().container.classList.add('tns-moving');
        });
        slider.events.on('transitionEnd', function () {
            slider.getInfo().container.classList.remove('tns-moving');
        });
        slider.events.on('dragMove', function () {
            slider.getInfo().container.classList.add('tns-moving');
        });

        item.querySelectorAll('img.b-lazy').forEach(image => {
            image.addEventListener('load', function(){
                slider.updateSliderHeight();
            });
        });
    });
}


var sliderrestart;

function pauseStart(slider, time) {

    slider.pause();
    clearTimeout(sliderrestart);
    sliderrestart = window.setTimeout(
        function () {
            slider.play();
        }, time
    )
}

function slowdownSlider(slider) {
}

function initialiseSliderState(slider, item, thisSlider, partOf, slides, description) {
    deactivateLinks(slides);
    activateLinks(slides[1]);


    if (item.parentElement.classList.contains('FacsimileSlider')) {
        if (document.body.clientWidth > 672) {
            setHeight(item, slides);
        }
    }

    if (item.classList.contains('CoverSlider__slider')) {
        var scale = parseFloat(getComputedStyle(item.parentElement.querySelector('.CoverSliderScale')).content.slice(1, -1));

        if (document.body.clientWidth > 835) {
            setHeight(item, slides, scale);
        } else {
            thisSlider.style.height = 'auto';
        }
    }

    if (item.parentElement.classList.contains('ImageSlider')) {
        if (document.body.clientWidth > 672) {
            setMaxHeight(item, slides);
        }
    }

    if (partOf) {
        setPartOf(((slides.length > 1) ? slider.getInfo().index : 0), slider.getInfo().slideCount, partOf);
    }

    if (item.classList.contains('CoverSlider__slider')) {
        enlargeCenter(slider, thisSlider);

        window.addEventListener('resize', function () {
            enlargeCenter(slider, thisSlider);
            if (document.body.clientWidth > 835) {
                setHeight(item, slides, scale);
            } else {
                thisSlider.style.height = 'auto';
            }
        })
    }

    /* Handle slide descriptions on load */
    /* Does not work anymore because .Slider__item:nth-child(2) etc. never returns a result, because .Slider__item elements are wrapped inside .tns-item and therefor are no sibblings.
     */
    if (item.querySelector('.Slider__item')) {

        if (description && slides.length > 1) {
            description.innerHTML = item.querySelector('.tns-item:nth-child(2) .Slider__item').getAttribute('data-description');
        } else if (description) {
            description.innerHTML = item.querySelector('.tns-item .Slider__item').getAttribute('data-description');
        }
    }
}


/* set height of a cover slider to its biggest Slide */
function setHeight(item, slides, scale) {

    var height = 0
    for (let slide of slides) {
        if (slide.querySelector('img')) {
            height = ((slide.querySelector('img').offsetHeight > height) ? slide.querySelector('img').offsetHeight : height);
        }
    }
    height = height * (scale + .1);
    item.querySelector('.Slider').style.height = height + 'px';
}

/* set height of a slider to its biggest Slide */
function setMaxHeight(item, slides) {
    let height = 0;
    let mesuredHeight = 0;
    for (let slide of slides) {
        if (slide.querySelector('img')) {
            height = ((slide.querySelector('img').offsetHeight > height) ? slide.querySelector('img').offsetHeight : height);
        }
    }


    if (item.parentElement.classList.contains('ImageSlider') && !item.classList.contains('ImageSlider--primaryFacsimile')) {
        const allItems = item.querySelectorAll('.Slider__item');
        let tempHeight = 0;

        allItems.forEach(function (singleItem) {
            mesuredHeight = singleItem.scrollHeight;

            if (mesuredHeight >= tempHeight) {
                tempHeight = mesuredHeight;
            }
        });

        allItems.forEach(function (item) {
            item.style.height = tempHeight + 'px';
        });
    }
}

/* activate links inside slide */
function activateLinks(slide) {
    if (slide) {
        slide.querySelectorAll('a').forEach(function (link) {
            link.setAttribute("tabindex", "0");
        })
    }
}

/*deactivate links inside slide */
function deactivateLinks(slides) {
    for (var i = 0; i < slides.length; i++) {
        var links = slides[i].querySelectorAll('a');
        links.forEach(function (link) {
            link.setAttribute("tabindex", "-1");
        })
    }
}

/* set the active navigation circle */
function removeActive(navItems) {
    navItems.forEach(function (item) {
        item.classList.remove('active');
    });
}

function setActive(navItem) {
    if (navItem) {
        navItem.classList.add('active');
    }
}

/* set the ___ von ___ text */
function setPartOf(index, slideCount, partOf) {
    var index = index + 1;

    partOf.querySelector('.PartOf__part').innerHTML = ' ' + index + ' ';
    partOf.querySelector('.PartOf__of').innerHTML = ' ' + slideCount;
}


/* enlarge the center of 3 slides */
function enlargeCenter(slider, thisSlider) {
    if (window.innerWidth >= 835) {
        /*var slides = thisSlider.querySelectorAll('.Slider__item');*/
        var slides = thisSlider.querySelectorAll('.tns-item');

        slides.forEach(function (slide) {
            slide.classList.remove('center');
        });

        if (thisSlider) {
            var center = '';

            /* works for no loop */

            if (slider.getInfo().index == 0) {
                center = thisSlider.querySelector(".tns-item");
            } else {
                center = thisSlider.querySelector(".tns-slide-active+.tns-slide-active");
                if (!center) {
                    // when slider gets initialised active is not set, so just enlarge second slide
                    center = thisSlider.querySelector(".tns-item+.tns-item");
                }
            }

            center.classList.add('center');
        }
    }
}


function updateDescription(description, slider) {
    if (description) {
        description.innerHTML = slider.getInfo().slideItems[slider.getInfo().index].getAttribute('data-description');
    }
}


