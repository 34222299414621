/**
 * Get the value of a single parameter from the URI
 *
 * @param {string} parameterName
 * @returns {null|string}
 */
function getQueryParameter(parameterName, searchString) {
    console.log(searchString);
    var searchString = searchString ? searchString.substring(1) : window.location.search.substring(1);
    var query = decodeURI(searchString);
    var vars = query.split('&');
    for (var i = 0, len = vars.length; i < len; i++) {
        var pair = vars[i].split('=');
        console.log(pair);
        if (pair[0] == parameterName) {
            return pair[1];
        }
    }
    return null;
}