
(function () {
    /** The document filter button for showing/hiding the filter **/
    Tollwerk.DocumentFilterButton = function () {
        // Get DOM elements
        this.button = document.getElementById('document-filter-button');
        this.filterForm = document.getElementById('filter');

        // Return null when essential something is missing
        if (!this.button || !this.filterForm) {
            return null;
        }

        // Add event listener to button for toggling the filter
        this.button.addEventListener('click', function (event) {
            if (this.button.hasAttribute('data-active')) {
                this.hideFilter();
            } else {
                this.showFilter();
            }
        }.bind(this));

        this.hideFilter();
    };

    /**
     * Show the filter
     */
    Tollwerk.DocumentFilterButton.prototype.showFilter = function () {
        this.filterForm.classList.remove('inactive');
        this.button.setAttribute('data-active', true);
    };

    /**
     * Hide the filter
     */
    Tollwerk.DocumentFilterButton.prototype.hideFilter = function () {
        this.filterForm.classList.add('inactive');
        this.button.removeAttribute('data-active');
    };

    /**
     * The document filter
     *
     * @returns {null|Tollwerk.DocumentFilter}
     * @constructor
     */
    Tollwerk.DocumentFilter = function () {
        // Get form and filter fields. Return null if no form was found.
        this.container = document.getElementById('document-plugin');
        this.form = document.getElementById('filter');
        this.headerFormContainer = document.getElementById('menu').querySelector('.NavPrimary__search');
        this.headerFormPlaceholder = this.headerFormContainer.querySelector('.Form-placeholder');
        this.headerForm = document.getElementById('header_filter');
        this.headerFormState = 0;
        this.list = document.getElementById('document-list');
        this.status = null;
        if (!this.headerForm) {
            var landmarkBanner = document.querySelector('.Landmark--banner');
            var bannerObserver = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'data-expanded') {
                        this.loadHeaderSearch();
                    }
                }.bind(this));
            }.bind(this));
            bannerObserver.observe(landmarkBanner, {
                attributes: true //configure it to listen to attribute changes
            });

            this.headerFormContainer.querySelector('.NavSearch__glass').addEventListener('click', function() {
                this.loadHeaderSearch();
            }.bind(this));
        } else {
            this.initializeHeaderSearch();
        }
        if (!this.form && !this.list && !this.headerForm) {
            return null;
        }

        // Create and initialize other elements
        this.resetButton = this.createResetButton();
        if (this.form) {
            this.readMoreButton = new TwReadMore.Element(this.form, '.FormFieldset__content > fieldset:nth-of-type(4) ~ *', 1);
        }

        // Mutation observer
        var observer = new MutationObserver(function (mutationsList, observer) {
            // Reset buttons
            if (this.form) {
                var resetButtons = this.form.querySelectorAll('.FormButton--reset-value');
                for (var i = 0, len = resetButtons.length; i < len; i++) {
                    resetButtons[i].addEventListener(
                        'click',
                        function(event){
                            event.preventDefault();
                            event.stopPropagation();
                            this.reset(event.currentTarget.dataset.target);
                        }.bind(this)
                    );
                }
            }
        }.bind(this));
        if (this.form) {
            observer.observe(this.form, {
                attributes: false,
                childList: true,
                subtree: true
            });
        }

        // Add event listeners to the whole form
        if (this.form) {
            this.form.addEventListener('reset', this._on_form_change.bind(this));
            this.form.addEventListener('change', this._on_form_change.bind(this));
            this.form.addEventListener('search', this._on_form_change.bind(this));
        }

        // Initialize stuff and return this object
        this.updateActiveFilterCounters(); // TODO: Move this function into Tollwerk.MultiCheckbox
        this.enhanceFilters();

        // Remove submit button
        //var submitButton = this.form.querySelector('button.FormButton--next[type="submit"]');
        //submitButton.parentNode.removeChild(submitButton);

        // Set ready status
        this.setStatus(Tollwerk.DocumentFilter.STATUS.ready);
        return this;
    };

    Tollwerk.DocumentFilter.STATUS = {
        ready: 'ready',
        loading: 'loading',
        locked: 'locked',
        error: 'error',
    };

    /**
     * Ajax loads the header search form
     */
    Tollwerk.DocumentFilter.prototype.loadHeaderSearch = function () {
        if (this.headerFormState !== 0) {
            setTimeout(function(){
                var headerForm = document.getElementById('header_filter');
                if (headerForm && window.getComputedStyle(headerForm).display !== 'none') {
                    headerForm.querySelector('#filter-searchHeader').focus();
                }
            }, 100);
            return;
        }
        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/?type=1910');
        xhr.onload = function(e) {
            var headerFormHtml = xhr.response.trim();
            var tmpFormContainer = document.createElement('div');
            tmpFormContainer.innerHTML = headerFormHtml;
            this.headerForm = tmpFormContainer.firstChild;
            this.headerFormContainer.replaceChild(this.headerForm, this.headerFormPlaceholder);
            this.initializeHeaderSearch();
            if (window.getComputedStyle(this.headerForm).display !== 'none') {
                this.headerForm.querySelector('#filter-searchHeader').focus();
            }
        }.bind(this);
        xhr.send();
        this.headerFormState = 1;
    }

    /**
     * Initialize the header search form filters
     */
    Tollwerk.DocumentFilter.prototype.initializeHeaderSearch = function () {
        this.readMoreButtonHeader = new TwReadMore.Element(this.headerForm, '.FormFieldset__content > fieldset:nth-of-type(4) ~ fieldset', 1);

        // Add event listeners to the whole form HEADER
        this.headerForm.addEventListener('reset', this.updateHeaderFilters.bind(this));
        this.headerForm.addEventListener('change', this.updateHeaderFilters.bind(this));
        this.headerForm.addEventListener('search', this.updateHeaderFilters.bind(this));
        this.updateHeaderFilters();
        this.enhanceHeaderFilters();
        this.headerForm.setAttribute('data-status', Tollwerk.DocumentFilter.STATUS.ready);
    }

    /**
     * Create proper ajax url for pagination
     * based on the href of the original paginator widget link that was clicked.
     *
     * @param paginatorHref
     */
    Tollwerk.DocumentFilter.prototype.createPaginatorAjaxUri = function (paginatorHref) {
        paginatorHref = paginatorHref.split('?')[1];
        var queryParams = new URLSearchParams(paginatorHref);
        var newQueryParams = new URLSearchParams();
        newQueryParams.set('tx_twziereis_documentlist[@widget_0][currentPage]', queryParams.get('tx_twziereis_documentlist[@widget_0][currentPage]'));
        newQueryParams.set('type', 1821);
        newQueryParams.set('twtrac_disable', 1);

        // Append all filter form values to queryParams
        var formValues = {};
        if (this.form) {
            var inputs = this.form.querySelectorAll('input');
            inputs.forEach(input => {
                switch (input.type) {
                    case 'checkbox':
                    case 'radio':
                        value = input.checked ? input.value : null;
                        break;
                    case 'hidden':
                        value = null;
                        break;
                    default:
                        value = input.value;
                }
                if (value && input.name) {
                    if (input.type == 'checkbox' || input.type == 'radio') {
                        var name = input.name.replace('[]', '');
                        if (!(name in formValues)) {
                            formValues[name] = [];
                        }
                        formValues[name].push(value);
                    } else {
                        formValues[input.name] = value;
                    }
                }
            });
        }
        //Header input fields
        for(var name in formValues) {
            queryParams.delete(name);
            if(Array.isArray(formValues[name])) {
                for(var i = 0, len = formValues[name].length; i < len; i++) {
                    newQueryParams.append(name + '[' + i + ']', formValues[name][i]);
                }
            } else {
                newQueryParams.set(name, formValues[name]);
            }
        }
        if (this.form) {
            var selects = this.form.querySelectorAll('select');
            selects.forEach(select => {
                if (select.name && select.value) {
                    newQueryParams.set(select.name, select.value);
                }
            });
        }
        return '/?' + newQueryParams.toString();
    };

    /**
     * Create the form reset button
     *
     * @returns {node}
     */
    Tollwerk.DocumentFilter.prototype.createResetButton = function () {
        // Find reset button container. Return null if not found.
        var resetButtonContainer = document.getElementById('filter-reset');
        if (!resetButtonContainer) {
            return null;
        }

        // Only create reset button if there are filters or the searchword set
        var filterResetButtons = this.form.querySelectorAll('.FormButton--reset-value');
        var searchWord = document.getElementById('filter-search');
        if (filterResetButtons.length == 0 && !searchWord.value) {
            return null;
        }

        // Create the reset button DOM element
        var resetButton = document.createElement('button');
        resetButton.type = 'button';
        resetButton.textContent = resetButtonContainer.attributes['data-text'].value || 'Reset';
        resetButton.addEventListener('click', function () {
            this.reset();
        }.bind(this));
        resetButtonContainer.innerHTML = '';
        resetButtonContainer.appendChild(resetButton);
        return resetButton;
    };

    /**
     * Create the form reset button
     *
     * @returns {node}
     */
    Tollwerk.DocumentFilter.prototype.createHeaderResetButton = function () {
        // Find reset button container. Return null if not found.
        var resetAllButtonContainer = document.getElementById('filter-resetHeader');
        var resetButtonContainer = document.querySelector('#filter-reset-filters-row-header > .FormFieldset__content');
        if (!resetButtonContainer) {
            return null;
        }
        var existingResetAllButton = resetAllButtonContainer.querySelector('button');
        if (existingResetAllButton) {
            resetAllButtonContainer.removeChild(existingResetAllButton);
        }

        // Only create reset button if there are filters or the searchword set
        var filterResetButtons = resetButtonContainer.querySelectorAll('.FormButton--reset-value');
        var searchWord = document.getElementById('filter-searchHeader');
        if (filterResetButtons.length == 0 && !searchWord.value) {
            return null;
        }

        // Create the reset button DOM element
        var resetButton = document.createElement('button');
        resetButton.type = 'button';
        resetButton.textContent = resetAllButtonContainer.attributes['data-text'].value || 'Reset';
        resetButton.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            this.resetHeaderForm();
        }.bind(this));
        resetAllButtonContainer.appendChild(resetButton);
    };

    /**
     * Reset all form elements
     * excluding 'Items per page' and 'Sorting'
     */
    Tollwerk.DocumentFilter.prototype.reset = function (fieldId) {
        if (typeof fieldId === 'undefined') {
            var filterFields = document.querySelectorAll('#filter-properties-row input');

            var searchField = document.getElementById('filter-search');
            if (searchField) {
                searchField.value = '';
            }
        } else {
            var filterFields = document.querySelectorAll('#' + fieldId);
        }

        for (var i = 0, len = filterFields.length; i < len; i++) {
            switch (filterFields[i].type) {
            case 'checkbox':
            case 'radio':
                filterFields[i].checked = false;
                filterFields[i].removeAttribute('checked');
                break;
            case 'text':
            case 'email':
            case 'search':
            case 'select':
                filterFields[i].value = '';
                break;
            }
        }

        // Create reset event and dispatch it
        // so other scripts can react to this reset.
        if (this.form) {
            this.form.dispatchEvent(new Event('change'));
        }
    };

    /**
     * Reset all form elements
     * excluding 'Items per page' and 'Sorting'
     */
    Tollwerk.DocumentFilter.prototype.resetHeaderForm = function (fieldId) {

        if (typeof fieldId === 'undefined') {
            var filterFields = document.querySelectorAll('#filter-properties-row-header input');

            var searchField = document.getElementById('filter-searchHeader');
            if (searchField) {
                searchField.value = '';
            }
        } else {
            var filterFields = document.querySelectorAll('#' + fieldId);
        }

        for (var i = 0, len = filterFields.length; i < len; i++) {
            switch (filterFields[i].type) {
            case 'checkbox':
            case 'radio':
                filterFields[i].checked = false;
                filterFields[i].removeAttribute('checked');
                break;
            case 'text':
            case 'email':
            case 'search':
            case 'select':
                filterFields[i].value = '';
                break;
            }
        }

        this.headerForm.dispatchEvent(new Event('change'));
    };

    /**
     * On form change
     * @param event
     * @private
     */
    Tollwerk.DocumentFilter.prototype._on_form_change = function (event) {
        if (event.target.classList.contains('text-filter')) {
            return null;
        }
        this.updateActiveFilterCounters();
        this.submit(event);
    };

    /**
     * Update the active filter counters
     */
    Tollwerk.DocumentFilter.prototype.updateHeaderFilters = function () {
        var resetButtonIcon = document.getElementById('filter-reset-button-icon');
        var resetButtonContainer = document.querySelector('#filter-reset-filters-row-header > .FormFieldset__content');
        resetButtonContainer.querySelectorAll('.FormButton--reset-value').forEach(function(resetButton) {
            resetButtonContainer.removeChild(resetButton);
        });

        var multiCheckboxFieldsHeader = this.headerForm.querySelectorAll('.FormFieldset--multi-checkbox');
        for (var x = 0, lenHeader = multiCheckboxFieldsHeader.length; x < lenHeader; x++) {
            var toggleHeader = multiCheckboxFieldsHeader[x].querySelector('.DocumentFilter__toggle');
            if (!toggleHeader) {
                continue;
            }

            var fieldLabel = multiCheckboxFieldsHeader[x].dataset.label;
            var selectedValues = multiCheckboxFieldsHeader[x].querySelectorAll('input:checked');
            var count = selectedValues.length;

            if (count) {
                toggleHeader.setAttribute('data-count', '(' + count + ')');
                for (var y = 0; y < count; y++) {
                    var checkboxElement = selectedValues[y];
                    var optionLabel = checkboxElement.parentElement.querySelector('.Multicheckbox__label');
                    var resetButton = document.createElement('button');
                    resetButton.classList.add('FormButton', 'FormButton--reset-value');
                    resetButton.id = 'filter-reset_value_' + checkboxElement.id;
                    resetButton.dataset.target = checkboxElement.id;
                    var buttonLabel = document.createElement('span');
                    buttonLabel.classList.add('Resetbutton__label');
                    buttonLabel.innerHTML = fieldLabel;
                    var buttonValue = document.createElement('span');
                    buttonValue.classList.add('Resetbutton__value');
                    buttonValue.innerHTML = optionLabel ? optionLabel.innerHTML : '';
                    var resetButtonIconClone = resetButtonIcon.cloneNode(true);
                    resetButtonIconClone.removeAttribute('hidden');
                    resetButtonIconClone.removeAttribute('id');
                    resetButton.appendChild(buttonLabel);
                    resetButton.appendChild(buttonValue);
                    resetButton.appendChild(resetButtonIconClone);
                    resetButton.addEventListener('click', function(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        this.resetHeaderForm(event.currentTarget.dataset.target);
                    }.bind(this));
                    resetButtonContainer.appendChild(resetButton);
                }
                continue;
            }
            toggleHeader.removeAttribute('data-count');
        }
        this.createHeaderResetButton();
    }

    /**
     * Update the active filter counters
     */
    Tollwerk.DocumentFilter.prototype.updateActiveFilterCounters = function () {
        if (this.form) {
            var multiCheckboxFields = this.form.querySelectorAll('.FormFieldset--multi-checkbox');
            for (var i = 0, len = multiCheckboxFields.length; i < len; i++) {
                var toggle = multiCheckboxFields[i].querySelector('.DocumentFilter__toggle');
                if (!toggle) {
                    continue;
                }

                var count = multiCheckboxFields[i].querySelectorAll('input:checked').length;

                if (count) {
                    toggle.setAttribute('data-count', '(' + count + ')');
                    continue;
                }
                toggle.removeAttribute('data-count');
            }
        }
    };

    /**
     * Enhance the MultiCheckbox filter elements
     */
    Tollwerk.DocumentFilter.prototype.enhanceFilters = function () {
        if (this.form) {
            var containers = this.form.querySelectorAll('.FormFieldset--multi-checkbox');
            for (var i = 0, len = containers.length; i < len; i++) {
                new Tollwerk.MultiCheckbox(containers[i]);
            }
        }
    };

    /**
     * Enhance the MultiCheckbox filter elements
     */
    Tollwerk.DocumentFilter.prototype.enhanceHeaderFilters = function () {
        if (this.headerForm) {
            var containersHeader = this.headerForm.querySelectorAll('.FormFieldset--multi-checkbox');
            for (var a = 0, lenH = containersHeader.length; a < lenH; a++) {
                new Tollwerk.MultiCheckbox(containersHeader[a]);
            }
        }
    };

    /**
     * Replace dom elements
     * @param response
     */
    Tollwerk.DocumentFilter.prototype.replaceDomElements = function (response) {
        // Replace result count
        var oldCount = document.getElementById('filter-number_of_results');
        var newCount = response.getElementById('filter-number_of_results');
        if (newCount && oldCount) {
            oldCount.textContent = newCount.textContent;
        }

        // Replace MultiCheckbox fields
        var newCheckboxFieldsets = response.querySelectorAll('.FormFieldset--multi-checkbox');
        for (var i = 0, len = newCheckboxFieldsets.length; i < len; i++) {
            var newCheckboxFieldset = newCheckboxFieldsets[i];
            var oldCheckboxFieldset = document.getElementById(newCheckboxFieldset.id);
            if (!oldCheckboxFieldset || !newCheckboxFieldset) continue;

            var oldInputsList = oldCheckboxFieldset.querySelector('.inputs-list');
            var newInputsList = newCheckboxFieldset.querySelector('.inputs-list');
            if (!newInputsList || !oldInputsList) continue;
            oldInputsList.innerHTML = newInputsList.innerHTML;
        }

        // Replace reset buttons
        var oldResetRow = document.getElementById('filter-reset-filters-row');
        var newResetRow = response.getElementById('filter-reset-filters-row');
        if (newResetRow && oldResetRow) {
            oldResetRow.innerHTML = newResetRow.innerHTML;
        }
        this.createResetButton();

        // Replace result list
        var oldList = document.getElementById('document-list-items');
        var newList = response.getElementById('document-list-items');
        if (newList && oldList) {
            oldList.innerHTML = newList.innerHTML;
        }

        // Replace pagination
        if (this.list) {
            var oldPagination = this.list.querySelector('.f3-widget-paginator');
        }
        var newPagination = response.querySelector('.f3-widget-paginator');
        if (oldPagination) {
            oldPagination.parentNode.removeChild(oldPagination);
        }
        if (newPagination) {
            if (this.list) {
                this.list.appendChild(newPagination);
            }
        }
        initializeSearchResultToDetailLinks();
    };

    /**
     * Submit the form via ajax,
     * update everything afterwards
     *
     * @param event
     */
    Tollwerk.DocumentFilter.prototype.submit = function (event) {
        event.preventDefault();

        // Prepare request
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'document';
        xhr.open('POST', this.container.dataset.ajaxFilterUrl);

        // On success
        xhr.addEventListener('load', function (event) {
            var response = event.currentTarget.response;
            this.replaceDomElements(response);
            this.updateActiveFilterCounters();
            this.setStatus(Tollwerk.DocumentFilter.STATUS.ready);
        }.bind(this));

        // On error
        xhr.addEventListener('error', function (event) {
            this.setStatus(Tollwerk.DocumentFilter.STATUS.error);
        }.bind(this));

        // Prepare form data and send request.
        // Add submit button value, e.g. for the reset buttons.
        this.setStatus(Tollwerk.DocumentFilter.STATUS.loading);
        if (event.target.nodeName === 'FORM') {
            var formData = new FormData(event.target);
        } else {
            var formData = new FormData(event.target.form);
        }
        if (event.currentTarget.type == 'submit') {
            formData.append(event.currentTarget.name, event.currentTarget.value);
        }
        formData.append('type', 1821);
        formData.append('twtrack_disable', 1);
        xhr.send(formData);
    };

    /**
     * Set the status
     *
     * @param status
     * @returns {null}
     */
    Tollwerk.DocumentFilter.prototype.setStatus = function (status) {
        // Check if status parameter is valid
        if (!status || !Tollwerk.DocumentFilter.STATUS.hasOwnProperty(status)) return null;

        // Determin which status to set
        switch (status) {
        default:
            this.status = status;
        }

        // Update all CSS classes
        if (this.form) {
            this.form.setAttribute('data-status', status);
        }
        if (this.list) {
            this.list.setAttribute('data-status', status);
        }
    };
})();

Tollwerk.Init.registerOnReady(function () {
    new Tollwerk.DocumentFilter();
    new Tollwerk.DocumentFilterButton();
});
