


TwTabList = function() {

    this.tablists = document.querySelectorAll('.Tabs');

    this.tablists.forEach(function (item) {
        //this.tabListElement = item;
        if (!item) {
            return;
        }

        this.tabListNav = item.querySelectorAll('.Tabs__nav-item a');

        this.openedTab = item.dataset.openedatstart;
        var that = this;
        this.tabContents = item.querySelectorAll('.Tabs__item');

        const mobileTabNav = () => {
            $('.Tabs__nav-item').children('a').removeClass('Tabs__prev-tab');
            $('.Tabs__nav-item').children('a').removeClass('Tabs__next-tab');
            $('.Tabs__nav-item').children('a[aria-selected="true"]').parent().prev().children('a').addClass('Tabs__prev-tab');
            $('.Tabs__nav-item').children('a[aria-selected="true"]').parent().next().children('a').addClass('Tabs__next-tab');
        }

        mobileTabNav();

        const switchTab = (oldTab, newTab) => {
            newTab.focus();
            // Set the selected state
            // Make the active tab focusable by the user (Tab key)

            newTab.removeAttribute('tabindex');
            oldTab.setAttribute('tabindex', '-1');
            oldTab.removeAttribute('aria-selected');
            oldTab.classList.remove('show');
            newTab.setAttribute('aria-selected', 'true');
            newTab.classList.add('show');


            // Make the inactive tab-content-container not focusable by the user
            // Make the active tab-content-container focusable by the user

            /*document.getElementById(oldTab.getAttribute('id') + '-content').hidden = true;*/
            document.getElementById(oldTab.getAttribute('id') + '-content').setAttribute('tabindex', '-1');
            document.getElementById(oldTab.getAttribute('id') + '-content').classList.remove('show');
            /*document.getElementById(newTab.getAttribute('id') + '-content').hidden = false;*/
            document.getElementById(newTab.getAttribute('id') + '-content').removeAttribute('tabindex');
            document.getElementById(newTab.getAttribute('id') + '-content').classList.add('show');

            mobileTabNav();
        }


        this.tabListNav.forEach.call(this.tabListNav, function (tab) {
            tab.addEventListener('click', function (e) {
                e.preventDefault();
                var currentTab = item.querySelector('[aria-selected]');
                switchTab(currentTab, this);
            })
        });

        // initialise tabnavigation
        Array.prototype.forEach.call(this.tabListNav, (tab, i) => {

            let listId = item.getAttribute('id');

            tab.setAttribute('role', 'tab');
            tab.setAttribute('tabindex', '-1');
            tab.parentNode.setAttribute('role', 'presentation');

            tab.addEventListener('keydown', e => {

                let activeList = document.getElementById(listId);
                let activeListNav = activeList.querySelectorAll('.Tabs__nav-items a');
                let activeListContent = activeList.querySelectorAll('.Tabs__item');

                // Get the index of the current tab in the tabs node list
                let index = Array.prototype.indexOf.call(activeListNav, e.currentTarget);
                // Work out which key the user is pressing and
                // Calculate the new tab's index where appropriate
                let dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
                if (dir !== null) {
                    e.preventDefault();
                    // If the down key is pressed, move focus to the open panel,
                    // otherwise switch to the adjacent tab
                    dir === 'down' ? activeListContent[i].focus() : activeListNav[dir] ? switchTab(e.currentTarget, activeListNav[dir]) : void 0;
                }
            });

        });

        //initialise Content
        Array.prototype.forEach.call(this.tabContents, (content, i) => {

            content.setAttribute('role', 'tabpanel');
            content.setAttribute('tabindex', '-1');
            let id = content.getAttribute('id');
            content.setAttribute('aria-labelledby', this.tabListNav[i].id);
            /*content.hidden = true;*/
        });

        if (this.openedTab) {
            this.tabListNav[this.openedTab].setAttribute('aria-selected', 'true');
            this.tabListNav[this.openedTab].setAttribute('tabindex', '0');
            /*this.tabContents[this.openedTab].hidden = false;*/
            this.tabContents[this.openedTab].removeAttribute('tabindex');

            mobileTabNav();
        } else {
            this.tabListNav[0].setAttribute('aria-selected', 'true');
            this.tabListNav[0].setAttribute('tabindex', '0');
            /*this.tabContents[0].hidden = false;*/
            this.tabContents[0].removeAttribute('tabindex');

            mobileTabNav();
        }
    });
}


window.addEventListener('load', function() {
    // Initialize Object
    var test = new TwTabList();
});
