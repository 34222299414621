FacsimileQuickfilter = function(container){
    this.container  = false;
    this.wrap       = false;
    this.button     = false;
    this.create(container);
};

FacsimileQuickfilter.prototype.create = function(container){
    if(container) {
        this.container  = container;
        this.wrap       = document.getElementById('quickfilter-box-wrap'); //$(this.container).find('.quickfilter-box-wrap');
        this.linkWrap   = document.querySelector('#quickfilter-link');
        this.button     = this.linkWrap.querySelector('#quickfilter-button'); //$(this.container).find('.button');

        this.wrap.classList.remove('show-all'); //$(this.wrap).removeClass('show-all');
        this.button.addEventListener('click', function(event){
            event.preventDefault();
            this.toggle();
            //this.toggle();
        }.bind(this)); //$(this.button).click($.proxy(this.toggle,this));
    }
};

FacsimileQuickfilter.prototype.toggle = function(){
    this.buttonText = this.button.querySelector('#quickfilter-button-text');
    if(this.container.classList.contains('show-all')){
        this.container.classList.remove('show-all');
        this.buttonText.innerHTML = this.button.getAttribute('data-label-specific');
        // this.fakeActive();
    }else{
        this.container.classList.add('show-all');
        this.buttonText.innerHTML = this.button.getAttribute('data-label-all');
    }
};

FacsimileQuickfilter.prototype.fakeActive = function() {
    this.listItem   = this.wrap.querySelectorAll('li');
    this.listItem.classList.add('active-item');
};

Tollwerk.Init.registerOnReady(function(){
	var container   = document.getElementById('facsimile-quickfilter');
    if (container) {
		new FacsimileQuickfilter(container);
    }
});
