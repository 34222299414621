(function () {
    TwReadMore = {};

    /**
     * @constructor
     */
    TwReadMore.Manager = function () {
        this.elements = document.querySelectorAll('[data-read-more]');
        for (var i = 0, len = this.elements.length; i < len; i++) {
            new TwReadMore.Element(this.elements[i]);
        }
    };

    /**
     * @constructor
     *
     * @param {node} container                  The DOM element containing the possible elements to hide/show.
     *
     * @param {string} elementsToHideSelector   CSS selector for the elements you want to hide.
     *                                          The "Read More"-Button will be inserted right in front of those.
     *
     * @para {int} hideOffset                   Number of elements elements found by elementsToHideSelector
     *                                          but which should NOT be hidden.
     *
     *                                          Example: You want to have the first three elements visible, all others hidden.
     *                                          If there are more than three elements, the "Read More"-button will show up
     *                                          as the new third element as expected and all others will be hidden.
     *
     *                                          But if you have exactly three elements there is no need for the "Read more"-button.
     *                                          For this to work you have to set the hideOffset to 1. Otherwise, the "Read more"-button
     *                                          will be inserted as the new third element and the actual third element will be hidden,
     *                                          probably feeling really lonely.
     *
     * @param {bool} placeButtonAfterContent
     *
     * @returns {object}
     */
    TwReadMore.Element = function (container, elementsToHideSelector, hideOffset, placeButtonAfterContent) {
        // Get root container
        this.container = container;
        if (!this.container) return null;
        this.container.classList.add('tw-readmore');

        // Get elements to hide, move them to a dedicated container.
        // Return null if there are no elements to hide.

        var eths = elementsToHideSelector || this.container.getAttribute('data-read-more') || null;
        this.elementsToHide = this.container.querySelectorAll(eths || 'p:first-of-type ~ *');
        var hideOffset = hideOffset || 0;
        if (!this.elementsToHide.length || (hideOffset && this.elementsToHide.length <= hideOffset)) return null;
        this.elementsToHideContainer = document.createElement('div');
        this.elementsToHideContainer.classList.add('tw-readmore__hide-elements');
        this.elementsToHideContainer.addEventListener('transitionend', function (event) {
            if (this.status == TwReadMore.Element.STATUS.active) {
                this.elementsToHideContainer.style.overflow = 'visible';
                return null;
            }
            this.elementsToHideContainer.style.overflow = 'hidden';
        }.bind(this));
        this.elementsToHide[0].parentNode.insertBefore(this.elementsToHideContainer, this.elementsToHide[0]);
        for (var i = 0, len = this.elementsToHide.length; i < len; i++) {
            this.elementsToHideContainer.appendChild(this.elementsToHide[i]);
        }

        this.maxHeight = this.elementsToHideContainer.clientHeight;

        // Create and add "Read more" button
        this.createReadMoreButton(placeButtonAfterContent);

        // Hide elements at start and store this entity for later use
        this.hideElements();
        this.container.twReadMore = this;
        return this.container;
    };

    /**
     * Status of the read more element
     * @type {{inactive: string, active: string}}
     */
    TwReadMore.Element.STATUS = {
        active: 'active',
        inactive: 'inactive'
    };

    /**
     * Create "Read more" button
     */
    TwReadMore.Element.prototype.createReadMoreButton = function (placeButtonAfterContent) {

        var placeButtonAfterContent = placeButtonAfterContent === true ? true : false;

        // Create button element
        this.readMoreButton = document.createElement('button');
        this.readMoreButton.setAttribute('type', 'button');
        this.readMoreButton.classList.add('tw-readmore__button');
        this.readMoreButton.twReadMore = {
            moreLabel: this.container.attributes['data-read-more-text'] ? this.container.attributes['data-read-more-text'].value : 'Read more',
            lessLabel: this.container.attributes['data-read-less-text'] ? this.container.attributes['data-read-less-text'].value : 'Read less'
        };

        // Create and add textSpan
        var textSpan = document.createElement('span');
        textSpan.textContent = this.readMoreButton.twReadMore.moreLabel;
        this.readMoreButton.appendChild(textSpan);

        this.readMoreButton.addEventListener('click', function () {
            this.toggle();
        }.bind(this));

        if(placeButtonAfterContent) {
            this.elementsToHideContainer.parentNode.insertBefore(this.readMoreButton, this.elementsToHideContainer.nextSibling);
        } else {
            this.elementsToHideContainer.parentNode.insertBefore(this.readMoreButton, this.elementsToHideContainer);
        }

    };

    /**
     * Show all elements
     */
    TwReadMore.Element.prototype.showElements = function () {
        /*this.elementsToHideContainer.style.maxHeight = this.maxHeight + 'px';*/
        this.elementsToHideContainer.style.maxHeight = 'none';
        this.elementsToHideContainer.style.overflow = 'visible';
        this.elementsToHideContainer.setAttribute('data-read-more-status', TwReadMore.Element.STATUS.active);
        this.status = TwReadMore.Element.STATUS.active;

        // If no label for "reading less" is given, the readMore button get's removed
        // and the now visible elements can not be hidden anymore.
        if (!this.readMoreButton.twReadMore.lessLabel) {
            this.readMoreButton.parentNode.removeChild(this.readMoreButton);
            return null;
        }

        this.readMoreButton.childNodes[0].textContent = this.readMoreButton.twReadMore.lessLabel;
        this.readMoreButton.classList.add('tw-readmore__button--active');

        // Enable all tabindex attributes of hidden input fields
        var inputElements = this.elementsToHideContainer.querySelectorAll('input, button, a, select, textarea');
        for (var i = 0, len = inputElements.length; i < len; i++) {
            inputElements[i].removeAttribute('tabindex');
        }
    };

    /**
     * Hide all elements
     */
    TwReadMore.Element.prototype.hideElements = function () {
        this.elementsToHideContainer.style.maxHeight = 0;
        this.elementsToHideContainer.style.overflow = 'hidden';
        this.elementsToHideContainer.setAttribute('data-read-more-status', TwReadMore.Element.STATUS.inactive);

        this.readMoreButton.childNodes[0].textContent = this.readMoreButton.twReadMore.moreLabel;
        this.status = TwReadMore.Element.STATUS.inactive;

        this.readMoreButton.classList.remove('tw-readmore__button--active');

        // Disable all tabindex attributes of hidden input fields
        var inputElements = this.elementsToHideContainer.querySelectorAll('input, button, a, select, textarea');
        for (var i = 0, len = inputElements.length; i < len; i++) {
            inputElements[i].setAttribute('tabindex', -1);
        }
    };

    /**
     * Toggle between showing and hiding all elements
     *
     * @returns {boolean}
     */
    TwReadMore.Element.prototype.toggle = function () {
        if (this.elementsToHideContainer.attributes['data-read-more-status'] && this.elementsToHideContainer.attributes['data-read-more-status'].value == TwReadMore.Element.STATUS.inactive) {
            this.showElements();
            return true;
        }
        this.hideElements();
        return false;
    }
})();

Tollwerk.Init.registerOnReady(function () {
    new TwReadMore.Manager();
});
