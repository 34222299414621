(function (d) {
    'use strict';

    /**
     * Blog List
     *
     * @param {Element} element DOM Element
     * @returns {null}
     * @constructor
     */
    function BlogList(element) {
        // Get base element
        this.list = element;
        if (!this.list) return null;

        // Check if there is a pagination.
        var nextSibling = this.list.nextSibling;
        if(!nextSibling || !nextSibling.classList.contains('Pagination')) {
            return null;
        }
        nextSibling.parentNode.removeChild(nextSibling);

        this.offset = 0;
        this.id = this.list.attributes['data-blog-id'].value;
        this.moreButton = null;

        // Preload more blog articles
        this.loadMore();
    }

    /**
     * Add the "More" button
     */
    BlogList.prototype.createMoreButton = function () {
        this.moreButton = d.createElement('button');
        this.moreButton.textContent = this.list.attributes['data-blog-more-text'] ? this.list.attributes['data-blog-more-text'].value : 'More';
        this.moreButton.className = 'CallToAction CallToAction--link CallToAction-theme--turquoise';
        this.moreButton.addEventListener('click', this.handleClick.bind(this));
        this.list.parentNode.insertBefore(this.moreButton, this.list.nextSibling);
    }

    /**
     * Handle click
     */
    BlogList.prototype.handleClick = function () {
        this.list.querySelectorAll('.BlogListItem[hidden]')
            .forEach(hiddenItem => hiddenItem.removeAttribute('hidden'));
        this.loadMore();
    }

    /**
     * Load more blog articles. Cancel all execution if no articles are found.
     * If articles are found, create "More"-button if not already existing and
     * insert the new articles as hidden items into the blog list. They will
     * be shown at the next click on the "More"-button.
     */

    BlogList.prototype.loadMore = function () {
        const uri = `/?${String.toUri({
            type: 1820,
            call: 'blog',
            args: {
                id: this.id,
                offset: this.offset 
            },
        })}&twtrack_disable=1`;

        const xhr = new XMLHttpRequest();
        xhr.open('GET', uri);
        xhr.addEventListener('load', function (event) {
            const result = JSON.parse(event.target.response).result;

            // Cancel execution if no new blog articles are found and remove "More"-Button
            if (!result.count) {
                if (this.moreButton) {
                    this.moreButton.parentNode.removeChild(this.moreButton);
                }
                return;
            }

            // Create the "More"-button if not yet existing
            if (!this.moreButton) {
                this.createMoreButton();
            }

            for (let item of result.htmlItems) {
                const div = d.createElement('div');
                div.innerHTML = item;
                const li = div.querySelector('.BlogListItem');
                li.setAttribute('class', 'BlogListItem BlogListItem-- col-lg-3 col-md-6');
                li.setAttribute('hidden', 'hidden');

                this.list.appendChild(li);
            }

            // Set offset for next request
            this.offset = result.nextOffset;
            // this.list.innerHTML += result.htmlItems.join('');

        }.bind(this));
        xhr.send();
    }

    Tollwerk.Init.registerOnReady(function () {
        d.querySelectorAll('[data-blog-list="default"]').forEach(item => {
            new BlogList(item);
        });
    });
})(document);



