(function () {

    Tollwerk.EnhancedTooltips = function (container) {
        try {
            var links = container ? container.querySelectorAll('[data-tooltip]') : document.querySelectorAll('[data-tooltip]');
            for (var i = 0, len = links.length; i < len; i++) {
                this.enhance(links[i]);
            }
        } catch (e) {

        }

    }

    Tollwerk.EnhancedTooltips.prototype.enhance = function (element) {
        var content = element.hasAttribute('data-tooltip') ? element.getAttribute('data-tooltip') : null;
        if (!content) return;
        tippy(element, {
            content: content,
            trigger: 'click, mouseenter',
            arrow: true,
            delay: 0,
        });
    }

})();

Tollwerk.Init.registerOnReady(function () {
    new Tollwerk.EnhancedTooltips();
    var observer = new MutationObserver(function (mutationsList, observer) {
        mutationsList.forEach(mutation => {
            mutation.addedNodes.forEach(addedNode => {
                new Tollwerk.EnhancedTooltips(addedNode);
            });
        });
    });
    observer.observe(document, {
        attributes: false,
        childList: true,
        subtree: true
    });
});
