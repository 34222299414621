(function () {

    /**
     * Check a field for fully qualified email address
     * including top-level-domain.
     *
     * @param field
     * @returns {null}
     * @constructor
     */
    Tollwerk.FormFieldEmailValidator = function (field) {
        // Get required DOM elements,
        // return null if something is missing.
        this.field = field;
        if (!this.field) {
            return null;
        }
        this.errorMessage = this.field.hasAttribute('data-email-invalid-text') ? this.field.getAttribute('data-email-invalid-text') : 'No valid email address';

        // Add event listeners for validating the field
        this.field.addEventListener('keyup', this.validate.bind(this));
    };

    /**
     * Check if this.field is empty,
     * set corresponding data-attributes
     */
    Tollwerk.FormFieldEmailValidator.prototype.validate = function () {
        let regexp = /\S+@\S+\.\S+/;
        if (this.field.value.match(regexp)) {
            console.log('valid');
            this.field.setCustomValidity('');
        } else {
            console.log('not valid');
            this.field.setCustomValidity(this.errorMessage);
        }
    };

    /**
     * Advanced password validator for checking
     * if both passwords match etc.
     *
     * @param passwordField
     * @param repeatPasswordField
     * @returns {null}
     * @constructor
     */
    Tollwerk.FormAdvancedPasswordValidator = function (passwordField, repeatPasswordField) {

        // Get required DOM elements,
        // return null if something is missing.
        this.passwordField = passwordField;
        this.repeatPasswordField = repeatPasswordField
        if (!this.passwordField) {
            return null;
        }

        this.errorMessage = this.passwordField.hasAttribute('data-error-matching') ? this.passwordField.getAttribute('data-error-matching') : 'Passwords do not match';

        // Add event listeners for validating the field
        this.passwordField.addEventListener('keyup', this.validate.bind(this));
        if(this.repeatPasswordField) {
            this.repeatPasswordField.addEventListener('keyup', this.validate.bind(this));
        }
    };

    /**
     * Check if values of this.passwordField and this.repeatPasswordField
     * are exactly the same
     *
     * @returns {boolean}
     */
    Tollwerk.FormAdvancedPasswordValidator.prototype.checkPasswordsMatching = function () {
        return (this.passwordField.value === this.repeatPasswordField.value);
    }

    /**
     * Check if there are validation errors
     * and show them immediately
     *
     * @param event
     */
    Tollwerk.FormAdvancedPasswordValidator.prototype.validate = function (event) {
        // Reset custom validity on start
        event.currentTarget.setCustomValidity('');

        // Check matching
        if(this.repeatPasswordField) {
            if (this.passwordField.value && this.repeatPasswordField.value) {
                if (!this.checkPasswordsMatching()) {
                    this.passwordField.setCustomValidity(this.errorMessage);
                    this.repeatPasswordField.setCustomValidity(this.errorMessage);
                } else {
                    this.passwordField.setCustomValidity('');
                    this.repeatPasswordField.setCustomValidity('');

                    // Bugfix for firefox: HTML5 error message still shows
                    // event after removing the custom validation error
                    passwordField.currentTarget.blur();
                    passwordField.currentTarget.focus();
                    repeatPasswordField.currentTarget.blur();
                    repeatPasswordField.currentTarget.focus();
                }
            }
        }

        // Show error message
        if(!event.currentTarget.checkValidity()) {
            event.currentTarget.reportValidity();
        }
    };

})();

/**
 * TODO: Add Mutation observer to entire document?
 */
Tollwerk.Init.registerOnReady(function () {

    /**
     * Init email validators
     */
    let emailFields = document.querySelectorAll('input[type="email"]');
    emailFields.forEach(emailFields => {
        new Tollwerk.FormFieldEmailValidator(emailFields);
    });

    /**
     * Init password validators
     */
    let advancedPasswordElements = document.querySelectorAll('.FormPassword');
    advancedPasswordElements.forEach(element => {
        passwordFields = element.querySelectorAll('input[type="password"]');
        new Tollwerk.FormAdvancedPasswordValidator(passwordFields[0], passwordFields[1]);
    });
});

