(function () {
    /**
     * Opens image links with photoswipe. See https://photoswipe.com/
     *
     * Only takes links into account which have all of the following attributes set:
     *
     * • class: Class list must contain "photoswipe"
     * • href: Uri to the image file
     * • rel: User can cylce through images with the same link rel value
     * • data-width: The pixel width of the linked image file
     * • data-height: The pixel height of the linked image file
     *
     * Additionally the links can have the following optional attributes
     * • data-title Get's displayed inside the photoswipe overlay
     * • data-description Get's displayed inside the photoswipe overlay
     *
     * @returns {TwPhotoswipe}
     * @constructor
     */
    TwPhotoswipe = function () {
        // Get the needed DOM elements, return null if not found.
        this.photoswipeElement = document.getElementById('pswp');
        if (!this.photoswipeElement) return null;
        this.defaultRelGroup = 'default';
        this.itemsByRel = this.getItemsByRel();
        this.activeGallery = null;


        // Set photoswipe options
        this.options = {
            shareButtons: false
        };

        return this;
    }

    /**
     * Get array of all photoswipe links
     * grouped by their rel attribute
     *
     * @returns {Array}
     */
    TwPhotoswipe.prototype.getItemsByRel = function () {
        var links = document.querySelectorAll('a.photoswipe');
        var itemsByRel = {};

        // Iterate over links, create photoswipe items, grouped by rel attribute
        for (var i = 0, len = links.length; i < len; i++) {
            var link = links[i];
            var width = link.hasAttribute('data-width') ? link.getAttribute('data-width') : null,
                height = link.hasAttribute('data-height') ? link.getAttribute('data-height') : null,
                rel = link.hasAttribute('rel') ? link.getAttribute('rel') : this.defaultRelGroup,
                title = link.hasAttribute('data-title') ? link.getAttribute('data-title') : null,
                alt = link.hasAttribute('data-alt') ? link.getAttribute('data-alt') : null,
                description = link.hasAttribute('data-description') ? link.getAttribute('data-description') : null;

            // Skip link if some necessary information is missing
            if (!width || !height || !rel) continue;

            if (!alt) {
                alt = title;
            }

            // Determine if it's an image or html link
            var href = link.href;

            // Add item to group
            if (!itemsByRel[rel]) itemsByRel[rel] = [];
            itemsByRel[rel].push({
                src: link.href,
                w: Number(width),
                h: Number(height),
                altAttribute: alt,
                titleAttribute: title,
                title: (title ? '<span class="pswp__caption__title">' + alt + '</span>' : '') + ( description ? ' </br><span class="pswp__caption__description">' + description + '</span>' : '')
            })
            // title: (title ? title : '') + ( description ? ' </br> ' + description : '')
            // title: (title ? '<span class="lightbox__title">' + title + '</span>' : '') + ( description ? ' </br><span class="lightbox__description">' + description + '</span>' : '')
            // title: (title ? title : '') + ( description ? ' – ' + description : '')
            // Add event listener to link
            link.addEventListener('click', this.open.bind(this));
        }

        return itemsByRel;
    }

    TwPhotoswipe.prototype.open = function (event) {
        var rel = event.currentTarget.hasAttribute('rel') ? event.currentTarget.getAttribute('rel') : this.defaultRelGroup;
         if (!rel) return null;
        //console.log(rel);
        var items = this.itemsByRel[rel];
        if (!items) return null;

        var options = this.options;
        var index = event.currentTarget.getAttribute('data-index');
        //console.log(event.currentTarget);
        if(index){
            options.index = Number(index);
        }

        event.preventDefault();
        this.activeGallery = new PhotoSwipe(this.photoswipeElement, PhotoSwipeUI_Default, items, this.options);
        this.activeGallery.init();
    }

})();

Tollwerk.Init.registerOnReady(function () {
    var photoswipe = new TwPhotoswipe();
});
