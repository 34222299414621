(function () {
    Tollwerk.MultiCheckbox = function (container) {

        // Get container, return null if not found
        this.container = container;
        if (!this.container) {
            return null;
        }

        // Get toggle button, return null if not found
        this.toggleButton = this.container.querySelector('.DocumentFilter__toggle');
        if (!this.toggleButton) {
            return null;
        }
        this.toggleButton.addEventListener('click', this.toggle.bind(this));

        // Create search field and add event listener
        this.searchField = document.createElement('input');
        this.searchField.type = 'search';
        this.searchField.classList.add('text-filter', 'form-control');
        this.searchField.placeholder = this.container.attributes['data-searchfield-placeholder'] ? this.container.attributes['data-searchfield-placeholder'].value : 'Filter';
        this.searchField.addEventListener('keyup', this.filter.bind(this));
        this.searchField.addEventListener('input', this.filter.bind(this));

        // Insert the search field into the DOM
        var insertBeforeElement = this.container.querySelector('.form-control-icon');
        insertBeforeElement.parentNode.insertBefore(this.searchField, insertBeforeElement);

        // Close on outside clicks
        document.addEventListener('click', function (event) {
            if (!this.container.contains(event.target)) {
                this.collapse();
            }
        }.bind(this));

        // Collapse at start
        this.expanded = null;
        this.collapse();
    }

    /**
     * Expand or collapse the element.
     * @returns {boolean}   Returns this.expanded
     */
    Tollwerk.MultiCheckbox.prototype.toggle = function (event) {
        if (event) {
            event.preventDefault();
        }
        if (this.expanded === true) {
            return this.collapse();
        }

        return this.expand();
    }

    /**
     * Expand the checkbox
     * @returns {boolean}   Returns this.expanded, which should always be true
     */
    Tollwerk.MultiCheckbox.prototype.expand = function () {
        this.toggleButton.classList.remove('collapsed');
        this.toggleButton.classList.add('expanded')

        /*
        ungeeignet für mobile
        if (this.searchField) {
            this.searchField.focus();
        }*/

        this.expanded = true;
        return this.expanded;
    }

    /**
     * Collapse the checkbox
     * @returns {boolean}   Returns this.expanded, which should always be false
     */
    Tollwerk.MultiCheckbox.prototype.collapse = function () {
        this.toggleButton.classList.remove('expanded');
        this.toggleButton.classList.add('collapsed');

        this.expanded = false;
        return this.expanded;
    }

    /**
     * Filter checkboxes by search string
     *
     * @returns {number|null}   Returns the number of still visible checkboxes.
     *                          Returns null if no filtering occured and all checkboxes are visible.
     */
    Tollwerk.MultiCheckbox.prototype.filter = function () {
        var checkboxContainers = this.container.querySelectorAll('* .Multicheckbox');
        var checkboxes = this.container.querySelectorAll('* input[type="checkbox"]');

        // Get the search string. If empty, show all checkboxes, uncheck them and return null
        var searchString = this.searchField.value.trim().toLowerCase();
        if (!searchString) {
            for (var j = 0, jLen = checkboxContainers.length; j < jLen; j++) {
                checkboxContainers[j].removeAttribute('hidden');
            }
            for (var j = 0, jLen = checkboxes.length; j < jLen; j++) {
                checkboxes[j].checked = false;
                checkboxes[j].dispatchEvent(new Event('change'));
            }
            return null;
        }

        // Show or hide checkboxes
        var visibleElements = [];
        for (var j = 0, jLen = checkboxContainers.length; j < jLen; j++) {
            var checkboxContainer = checkboxContainers[j];
            var label = checkboxContainer.querySelector('.Multicheckbox__label')
            var labelText = (label && label.textContent) ? label.textContent.trim().toLowerCase() : null;
            if (labelText && labelText.indexOf(searchString) > -1) {
                checkboxContainer.removeAttribute('hidden');
                visibleElements.push(checkboxContainer);
            } else {
                checkboxContainer.setAttribute('hidden', true);
            }
        }

        // Finally, return the number of visible checkboxes
        return visibleElements.length;
    }
})();
