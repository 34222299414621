(function () {
    /**
     * Add/remove from wishlist, stocklist etc. for html element
     *
     * @constructor
     */
    Tollwerk.RemoveFromList = function (element) {
        // Get DOM elements and attributes
        this.element = element;
        if (!element) return;
        this.listName = this.element.getAttribute('data-list-name');
        this.listAction = this.element.getAttribute('data-list-action');
        this.facsimile = this.element.getAttribute('data-facsimile');
        if (!this.listName || !this.listAction || !this.facsimile) return;

        // Get initial status (on list, not on list)
        this.init();
    }

    Tollwerk.RemoveFromList.prototype.addClickListner = function () {
        this.element.addEventListener('click', function (event) {
            event.preventDefault();

            // Prepare request
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'json';
            xhr.open('GET', '/?type=1820&twtrack_disable=1&call=' + this.listName + '&args[action]=' + this.listAction + '&args[facsimile]=' + this.facsimile);

            this.element.classList.add('loading');

            // On success
            xhr.addEventListener('load', function (event) {

                // Find parent <li>-Element of the element
                var parentListElement = this.element.closest('li');
                if (this.element.hasAttribute('data-list-remove-element')) {
                    parentListElement.parentElement.removeChild(parentListElement);
                }

                var plusIcon = this.element.querySelector('.Icon.plus');
                var checkIcon = this.element.querySelector('.Icon.check');

                if (this.listAction === 'add') {
                    if (plusIcon) {
                        plusIcon.setAttribute('hidden', true);
                    }
                    if (checkIcon) {
                        checkIcon.removeAttribute('hidden');
                    }
                } else if (this.listAction === 'remove') {
                    if (checkIcon) {
                        checkIcon.setAttribute('hidden', true);
                    }
                    if (plusIcon) {
                        plusIcon.removeAttribute('hidden');
                    }
                }
                this.listAction = this.listAction === 'add' ? 'remove' : 'add';
                this.element.title = this.listAction === 'remove' ? (this.element.hasAttribute('data-remove-from-list-text') ? this.element.getAttribute('data-remove-from-list-text') : '') : (this.element.hasAttribute('data-add-to-list-text') ? this.element.getAttribute('data-add-to-list-text') : '');
                this.element.setAttribute('data-list-action', this.listAction);
                this.element.classList.remove('loading');
            }.bind(this));
            xhr.send();

        }.bind(this));
    }

    Tollwerk.RemoveFromList.prototype.init = function () {
        // Prepare request
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open('GET', '/?type=1820&twtrack_disable=1&call=facsimileOnList&args[listname]=' + this.listName + '&args[facsimileUid]=' + this.facsimile);

        xhr.addEventListener('load', function (event) { 

            var plusIcon = this.element.querySelector('.Icon.plus');
            var checkIcon = this.element.querySelector('.Icon.check');

            if (event.target.response.result.isOnList) {
                if (plusIcon) {
                    plusIcon.setAttribute('hidden', true);
                }
                if (checkIcon) {
                    checkIcon.removeAttribute('hidden');
                }
                this.listAction = 'remove';
                this.element.title = this.element.hasAttribute('data-remove-from-list-text') ? this.element.getAttribute('data-remove-from-list-text') : '';
                this.element.setAttribute('data-list-action', 'remove');
            } else {
                if (checkIcon) {
                    checkIcon.setAttribute('hidden', true);
                }
                if (plusIcon) {
                    plusIcon.removeAttribute('hidden');
                }
                this.listAction = 'add';
                this.element.title = this.element.hasAttribute('data-add-to-list-text') ? this.element.getAttribute('data-add-to-list-text') : '';
                this.element.setAttribute('data-list-action', 'add');
            }

            this.addClickListner();
            this.element.removeAttribute('hidden');

        }.bind(this));
        xhr.send();
    }

})();


Tollwerk.Init.registerOnReady(function () {
    // Define and call function to find and enhance "Delete from list" links.
    // The function get's stored in a variable to be called again by the
    // mutation observer, when the DOM get's changed.
    var enhanceRemoveLinks = function (container) {
        var container = container ? container : document;
        if (!container || !container.querySelectorAll) {
            return null;
        }
        var links = container.querySelectorAll('[data-list-action]');
        for (var i = 0, len = links.length; i < len; i++) {
            new Tollwerk.RemoveFromList(links[i]);
        }
    };
    enhanceRemoveLinks();

    // Define the mutation observer
    var observer = new MutationObserver(function (mutationsList, observer) {
        for (var i = 0, len = mutationsList.length; i < len; i++) {
            var mutation = mutationsList[i];
            for (var n = 0, nLen = mutation.addedNodes.length; n < nLen; n++) {
                enhanceRemoveLinks(mutation.addedNodes[n]);
            }
        }
    });
    observer.observe(document, {
        attributes: false,
        childList: true,
        subtree: true
    });
});
