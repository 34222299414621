Tollwerk.Init.registerOnReady(function () {
    /**
     * Create and return a click handler setting a cookie and optionally counting server-side
     *
     * @param {String} bannerName Banner name
     * @param {String} cookieName Cookie name
     * @param {Boolean} preventDefault Prevent default action
     * @param {Boolean} count Server side counting
     * @return {function(...[*]=)}
     */
    function createCookieHandler(bannerName, cookieName, preventDefault, count) {
        return function (e) {

            const xhr = new XMLHttpRequest();
            xhr.open('GET', '/?type=1820&twtrack_disable=1&call=ePrivacyConsent&args[accept]=' + this.value, true);
            xhr.send();

            if (cookieName) {
                const expire = new Date();
                const now = expire.toISOString();
                expire.setUTCFullYear(expire.getUTCFullYear() + 1);
                document.cookie = cookieName + '=' + now + ';path=/;max-age=31536000;expires=' + expire.toUTCString() + ';secure';
            }
            Tollwerk.Banner.dismiss(bannerName);
            if (preventDefault) {
                e.preventDefault();
            }
        };
    }

    // Configure all buttons to track
    const buttons = {
        'eprivacy-consent-button-all': ['eprivacy-consent', null, true, true],
        'eprivacy-consent-button-necessary': ['eprivacy-consent', null, true, true],
    };

    // Enhance buttons
    for (let b in buttons) {
        const btn = document.getElementById(b);
        if (btn) {
            btn.addEventListener('click', createCookieHandler.apply(null, buttons[b]));
        }
    }
});

