

(function () {
    /**
     * Enhances glossary links with ajax and lightbox/popup behaviour
     * @constructor
     */
    Tollwerk.Glossary = function () {
        // Set defaults for tippy popover library
        tippy.setDefaults({
            arrow: true,
            delay: 0,
            distance: 20,
            duration: 0,
            flipBehavior: ['right','bottom','left','top'],
            flipOnUpdate: true,
            interactive: true,
            maxWidth: 600,
            placement: 'right',
            zIndex: 30
        });

        // Enhance glossary links with lighbox etc.
        this.enhanceGlossaryLinks();
    }

    /**
     * Enhances glossary links with ajax and lightbox/popup behaviour
     */
    Tollwerk.Glossary.prototype.enhanceGlossaryLinks = function () {
        var glossaryLinks = document.querySelectorAll('a.Glossary__link');
        for (var i = 0, len = glossaryLinks.length; i < len; i++) {
            new Tollwerk.GlossaryLink(glossaryLinks[i]);
        }
    }

    /**
     * A single glossary link
     *
     * @param element
     * @returns {null}
     * @constructor
     */
    Tollwerk.GlossaryLink = function (element) {
        this.element = element;
        if (!this.element) return null;
        this.tippy = null;
        this.ajaxLoaded = false;

        this.element.addEventListener('click', function (event) {
            event.preventDefault();

            // Initialize popover, open it
            if (!this.tippy) {
                this.tippy = tippy(this.element, {
                    content: document.getElementById('glossary-link-loading-text').getAttribute('data-text'), // TODO: Get from translatable data-attribute
                    animateFill: false,
                    animation: 'fade',
                    flipOnUpdate: true,
                    trigger: 'click',
                    showOnInit: true,
                    onShow: function(event){
                        if(!this.ajaxLoaded){
                            var xhr = new XMLHttpRequest();
                            xhr.responseType = 'document';
                            xhr.open('GET', this.element.href);
                            xhr.addEventListener('load', function (event) {
                                var response = event.currentTarget.response;
                                var content = response.querySelector('.Glossary__singleview')
                                this.tippy.setContent(content);
                                this.ajaxLoaded = true;
                            }.bind(this));
                            xhr.send();
                        }
                    }.bind(this)
                });
            }
        }.bind(this));
    }
})();

Tollwerk.Init.registerOnReady(function () {
    new Tollwerk.Glossary();
});
