Tollwerk.Init.registerOnReady(function () {
    // Lazyloading images: Remove either the src or srcset attribute of images
    var removeAttribute = ('srcset' in document.createElement('img')) ? 'src' : 'srcset';
    document.querySelectorAll('img[srcset][src]').forEach(function (img) {
        img.removeAttribute(removeAttribute);
    });
    stickybits('.sticky', {stickyBitStickyOffset: 85});

    var lastheight = document.querySelector('.Landmark--banner').clientHeight;

    window.addEventListener('resize', function(){
        if (window.innerWidth < 833 ) {
            document.querySelector('.Landmark--banner').style.height = 'auto';
            if (document.querySelector('.Landmark--banner[data-expanded=true]')) {
                document.querySelector('.Landmark--banner[data-expanded=true]').style.height = '100vh';
            }
        } else {
            document.querySelector('.Landmark--banner').setAttribute('data-expanded', 'false');
            document.querySelector('.NavPrimary__toggle').setAttribute('aria-expanded', 'false');

            document.querySelector('.Landmark--banner').style.height = 'auto';
            landmarkHeight = `${document.querySelector('.Landmark--banner').clientHeight}px`;

            if(landmarkHeight != '0px') {
                document.querySelector('.Landmark--banner').style.height = landmarkHeight;
            }
        }

        if(lastheight != document.querySelector('.Landmark--banner').clientHeight &&
            document.querySelector('.Landmark--banner').getAttribute('data-expanded') != 'true') {
            margeHeader();
            lastheight = document.querySelector('.Landmark--banner').clientHeight;
        }
    });

    // Marge elements before header and adjust their size if neccesary
    function margeHeader() {
        var firstElementInsideMain = document.querySelector('main > *:first-child');

        if(firstElementInsideMain.classList.contains('Heading--hidden')) {
            firstElementInsideMain = document.querySelector('main > *:nth-child(2)');
        }

        var landmarkHeight = document.querySelector('.Landmark--banner .Landmark--meta').clientHeight;
        landmarkHeight += document.querySelector('.Landmark--banner .Landmark--main-navigation').clientHeight;
        landmarkHeight = `${landmarkHeight}px`;

        if (window.innerWidth < 833 ) {
            document.querySelector('.Landmark--banner').style.height = 'auto';
            if (document.querySelector('.Landmark--banner[data-expanded=true]')) {
                document.querySelector('.Landmark--banner[data-expanded=true]').style.height = '100vh';
            }
        } else {
            document.querySelector('.Landmark--banner').style.height = landmarkHeight;
        }

        if (firstElementInsideMain) {
            switch (true) {
                case firstElementInsideMain.classList.contains('ScrollNavigation__content'):
                    zeroMarge(firstElementInsideMain);
                    if (window.innerWidth < 833 ) {
                        zeroMarge(firstElementInsideMain.querySelector('.DocumentHero'));
                    } else {
                        paddingMarge(firstElementInsideMain.querySelector('.DocumentHero'), landmarkHeight);
                    }
                    break;
                case firstElementInsideMain.classList.contains('Hero'):
                    paddingMarge(firstElementInsideMain, landmarkHeight);
                    break;
                case firstElementInsideMain.classList.contains('HeroMood'):
                    paddingMarge(firstElementInsideMain, landmarkHeight);
                    break;
                case  firstElementInsideMain.classList.contains('_BlogArticle'):
                    zeroMarge(firstElementInsideMain);
                    break;
                case  firstElementInsideMain.classList.contains('BlogSlider--fullscreen'):
                    //marginMarge(firstElementInsideMain, landmarkHeight);
                    firstElementInsideMain.querySelectorAll('.BlogSlide--fullscreen').forEach(function (slide) {
                        adjustHeight(slide, landmarkHeight);
                    });
                    break;
                case  firstElementInsideMain.classList.contains('BlogArticle'):
                    zeroMarge(firstElementInsideMain);
                    //marginMarge(firstElementInsideMain.querySelector('.BlogSlider--fullscreen'), landmarkHeight);
                    firstElementInsideMain.querySelectorAll('.BlogSlide--fullscreen').forEach(function (slide) {
                        adjustHeight(slide, landmarkHeight);
                    });
                    break;
                default:
                    //marginMarge(firstElementInsideMain, landmarkHeight);
            }
        }
    }

    function paddingMarge(elem, height) {
        elem.style.paddingTop = height;
    }

    // function marginMarge(elem, height) {
    //     elem.style.marginTop = 'calc(' + height + ' - 0px)';
    // }

    function zeroMarge(elem) {
        elem.style.paddingTop = 0;
    }

    // streches Element to 100vh minus the height for the Header
    function adjustHeight(elem, height) {
        elem.style.height = 'calc(100vh - ' + height + ')';
    }

    // margin Content
    if (document.querySelector('.Landmark--main') && document.querySelector('.Landmark--banner')) {
        margeHeader();
        document.querySelector('html').classList.add('content-marged');
    }

    Tollwerk.BreakpointManager.resize(margeHeader);
    /*window.addEventListener('resize', margeHeader);*/

    // Offline caching
    try {
        if (!pathMatchesPattern(window.location.pathname, dontCachePaths)) {
            var breadcrumbs = document.querySelectorAll('.Breadcrumb li');
            if (breadcrumbs.length) {
                var title = Array.prototype.slice.call(breadcrumbs).map(function (breadcrumb) {
                    return breadcrumb.innerText.trim();
                }).join(' › ');
            } else {
                var title = document.getElementsByTagName('title')[0].innerText;
            }
            var data = {
                title: title,
                path: window.location.pathname || '/',
                date: new Date()
            };
            localStorage.setItem('offline-cache:' + data.path, JSON.stringify(data));
        }
    } catch (exception) {
        // No support for cached pages listing
    }
});
