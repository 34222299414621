Tollwerk.Init.registerOnReady(function () {

    const landmarkBanner = document.querySelector('.Landmark--banner');
    const primaryMenu = document.querySelector('.NavPrimary__menu-main');
    if (primaryMenu) {
        primaryMenu.setAttribute('id', 'menu-wrapper');

        // Create the toggle button
        const toggleButton = document.createElement('button');
        toggleButton.setAttribute('aria-controls', 'menu');
        toggleButton.setAttribute('aria-expanded', 'false');
        document.querySelectorAll('.NavPrimary__toggle').forEach(function (toggleLink) {
            toggleButton.className = toggleButton.className || toggleLink.className;
            const toggleButtonContent = document.createElement('span');
            toggleButtonContent.innerHTML = toggleLink.innerHTML;
            toggleButton.appendChild(toggleButtonContent);
            toggleLink.parentNode.removeChild(toggleLink);
        });
        toggleButton.className = 'NavPrimary__toggle';
        toggleButton._expanded = false;

        toggleButton.addEventListener('click', function () {
            this._expanded = !this._expanded;
            this.setAttribute('aria-expanded', this._expanded ? 'true' : 'false');
            landmarkBanner.setAttribute('data-expanded', this._expanded ? 'true' : 'false');
        });
        primaryMenu.parentNode.insertBefore(toggleButton, primaryMenu.parentNode.firstChild);

        // Menu buttons
        const menuButtons = document.querySelectorAll('.NavPrimary__menu-main button.Landmark__nav-item');

        const expandOnly = function (event) {
            var self = this;
            self._expand(self);
        };

        const expand = function (expanded) {
            this._expanded = expanded;
            this.setAttribute('aria-expanded', this._expanded ? 'true' : 'false');
            //landmarkBanner.setAttribute('data-expanded', this._expanded ? 'true' : 'false');
            // (this._controls.hidden = !this._expanded) ? this._controls.setAttribute('hidden', 'hidden') : this._controls.removeAttribute('hidden');
            if (!this._expanded) {
                this.blur();
            }
        };

        const toggle = function () {
            this[this._expanded ? '_expand' : '_expandOnly'](!this._expanded);
            if (!this._expanded) {
                this.blur();
            } else {
                this.focus();
            }
        };

        menuButtons.forEach(function (menuButton) {
            //menuButton._expanded = menuButton.parentNode.classList.contains('active');
            menuButton._expanded = false;
            menuButton._controls = document.getElementById(menuButton.getAttribute('aria-controls'));
            menuButton._expandOnly = expandOnly;
            menuButton._expand = expand;
            menuButton.addEventListener('click', toggle);
            /*menuButton.addEventListener('mouseleave', toggle);*/
            menuButton._expand(menuButton._expanded);

        });
        primaryMenu.parentNode.classList.remove('NavPrimary--initial');
    }

    const isHidden = function (el) {
        const element = document.getElementById(el);
        const style = window.getComputedStyle(element);
        return (style.display === 'none');
    };

    var hidden;

    const toggleHeaderVisibility = function (area, scrollPos) {
        const header = document.querySelector('.Landmark--transparent');
        if (!header) {
            return false;
        }
        const headerHeight = header.scrollHeight;
        const heroMood = document.querySelector('.HeroMood');
        const heroMoodHeight = heroMood.scrollHeight;
        const mobileMenu = document.querySelector('.NavPrimary__toggle');
        const mobileMenuAria = mobileMenu.getAttribute('aria-expanded');
        const windowOffset = window.scrollY;

        if (heroMood && !mobileMenu) {
            header.classList.add('color-transparent');
        }

        if (area === 'menu') {
            if (mobileMenuAria === 'true') {
                header.classList.remove('color-transparent');
            } else {
                if (windowOffset === 0) {
                    header.classList.remove('color-transparent');
                } else {
                    header.classList.add('color-transparent');
                }
            }
        }

        if (area === 'scrolling') {
            if ((document.body.getBoundingClientRect()).top > scrollPos) {
                if (windowOffset < headerHeight) {
                    if (mobileMenuAria === 'true' && windowOffset === 0) {
                        header.classList.remove('color-transparent');
                    } else {
                        header.classList.add('color-transparent');
                    }
                } else {
                    header.classList.remove('color-transparent');
                }
            } else {
                if (windowOffset > heroMoodHeight) {
                    header.classList.remove('color-transparent');
                } else {
                    header.classList.add('color-transparent');
                }
            }
        }
    };

    const button = document.querySelector('.NavPrimary__toggle');
    button.addEventListener('click', function () {
        toggleHeaderVisibility('menu');
        var banner =  document.querySelector('.Landmark--banner');

        if(banner.getAttribute('data-expanded') == 'true') {
            banner.style.height = '100vh';
        } else {
            banner.style.height = 'auto';
        }
    });

    var navSearch = document.querySelector('.NavPrimary__search');
    var navSearchGlass = document.querySelector('.NavSearch__glass ');

    if (navSearch) {
        // navSearch.addEventListener('click', function () {
        //     document.querySelector('#PrimaryNavgiationSearch').focus();
        // });

        document.addEventListener('click', function (event) {
            var isClickInside = navSearch.contains(event.target);

            if (!isClickInside) {
                navSearchGlass.setAttribute('aria-expanded', 'false');
                navSearchGlass._expanded  = false;
            }
        });
    }

    // detect scrolling

    // Initial state
    const header = document.querySelector('.Landmark--banner');

    document.addEventListener('click', function(event) {
        var isClickInside = header.contains(event.target);
        if (!isClickInside) {
            menuButtons.forEach(function(button){
                if(button._expanded) {
                    button.toggle;
                    button._expanded = false;
                    button._expand(button._expanded);
                    button.blur();
                }
            })
        }
    });

    let scrollPos = 0;

    if(header) {
        header.classList.add('visible');
    }

    // TODO: Reenable and fix iPhone bugs
    // window.addEventListener('scroll', function () {
    //     headerScrolling();
    // });

    // TODO: Reenable and fix iPhone bugs
    // function headerScrolling(){
    //     if (scrollPos) {
    //         toggleHeaderVisibility('scrolling', scrollPos);
    //     }
    //
    //     // If mobile menu is not expanded, do hiding and reappearing
    //     if (header.querySelector('.NavPrimary__toggle').getAttribute('aria-expanded') == 'false') {
    //         if ((document.body.getBoundingClientRect()).top > scrollPos) {
    //             // scrolling up
    //             clearTimeout(hidden);
    //             header.classList.remove('hidden');
    //             header.classList.remove('hiding');
    //             header.classList.add('visible');
    //             scrollPos = (document.body.getBoundingClientRect()).top;
    //         } else {
    //             //scrolling down
    //             if (document.body.getBoundingClientRect().top < scrollPos - 99) {
    //                 header.classList.remove('start');
    //                 header.classList.remove('visible');
    //                 header.classList.add('hiding');
    //                 hidden = window.setTimeout(function () {
    //                     header.classList.add('hidden');
    //                 }, 200);
    //                 scrollPos = (document.body.getBoundingClientRect()).top;
    //             }
    //         }
    //     }
    // }
});
